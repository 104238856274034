import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Link from "@/components-modern/atoms/link/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

interface PreviousBoxProps {
  prevLink: string;
  prevIsEnabled: boolean;
  prevLabel: string;
  prevLinkProps: {
    scroll?: boolean;
    replace?: boolean;
    [key: string]: unknown;
  };
  trackClick: (args: { eventLabel: string }) => void;
}

const PreviousBox: React.FC<PreviousBoxProps> = ({
  prevLink,
  prevIsEnabled,
  prevLabel,
  prevLinkProps,
  trackClick,
}) => {
  return (
    <Box sx={{ width: "15%" }}>
      <Link
        href={prevLink || ""}
        id="prevLink"
        variant="text"
        pointerEvents={prevIsEnabled && prevLink ? "auto" : "none"}
        linkStyles={{
          visibility: prevIsEnabled && prevLink ? "visible" : "hidden",
        }}
        onClick={() => trackClick({ eventLabel: prevLabel })}
        {...prevLinkProps}>
        <Stack display="flex" alignItems="center" flexDirection="row">
          <Stack flexDirection="row" justifyContent="center" alignItems="center" width=".5rem" mr={1}>
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: "100%" }} />
          </Stack>
          <Typography variant="subtitle2" sx={{ display: { xs: "none", md: "flex" } }}>
            {prevLabel}
          </Typography>
        </Stack>
      </Link>
    </Box>
  );
};

export default PreviousBox;
