const HamburgerIcon = () => (
  <svg
    fill="currentColor"
    stroke="currentColor"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    alt="open menu">
    <path d="m3 18h18v-2h-18zm0-5h18v-2h-18zm0-7v2h18v-2z" fill="currentColor" />
  </svg>
);

export default HamburgerIcon;
