/* eslint-disable @next/next/no-sync-scripts, @next/next/next-script-for-ga */
import React, { useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import SlidingNav from "@/components/Layouts/NewNavbar/SlidingNav";
import MobileNav from "@/components/Layouts/NewNavbar/MobileNav";
import { Box, SxProps } from "@mui/material";
import { SelectedTopic } from "@/lib/Practice/types";
import { Subject, Unit } from "@/store/api/graphql/generated/types";
import TopNav from "@/components-modern/molecules/top-nav/TopNav";
import { useHasCramMode } from "@/hooks/Library/useHasCramMode";
import useSaveSubjectPaywall from "@/hooks/Library/useSaveSubjectPaywall";
import SaveSubjectPaywall from "@/components-modern/molecules/save-subject-paywall/SaveSubjectPaywall";
import { useGetNavWidth } from "@/hooks/Library/useIsAPBio";
import Footer from "./Footer";
import meta from "../../meta";
import Meta, { MetaProps } from "./Meta";
import BottomNav from "./NewNavbar/BottomNav";
import JsonLD from "../Library/JsonLD";

const calculateBoundingPadding = (
  fullWidthDesktop: boolean,
  navState: boolean,
  hideNav: boolean,
  showNavDesktop: boolean,
  navWidth: number
): SxProps => {
  const defaultPadding = { xs: "16px", md: "32px" };

  if (fullWidthDesktop) return { ...defaultPadding, md: "0" };
  if (hideNav) return { ...defaultPadding, md: `32px 32px 32px ${navWidth + 50}px` };
  if (showNavDesktop && navState) return { ...defaultPadding, md: `32px 32px 32px ${navWidth + 50}px` };

  return defaultPadding;
};

export type LayoutProps = MetaProps & {
  className?: string;
  children: React.ReactNode;
  // as of now, this is only used with subject/unit/content/practice pages
  pageData?: {
    content?: { id: string; topics: SelectedTopic[]; slug: string };
    subject?: Subject;
    unit?: Unit;
  };
  pageStyles?: SxProps;
  hideNav?: boolean;
  hideNavDesktop?: boolean;
  hideFooter?: boolean;
  hideBottomNav?: boolean;
  hideTopNav?: boolean;
  navDefaultState?: boolean;
  fullWidthDesktop?: boolean;
};

// eslint-disable-next-line max-lines-per-function
const Layout = ({
  className,
  children,
  pageData,
  hideNav = false,
  hideNavDesktop = false,
  hideFooter = false,
  hideBottomNav = false,
  hideTopNav = false,
  navDefaultState = true,
  fullWidthDesktop = false,
  pageStyles = {},

  // meta info, could refactor to use meta object
  title,
  description,
  imgPath,
  urlPath,
  schemaData,
  shouldNoIndex,
}: LayoutProps): JSX.Element => {
  const hasCramMode = useHasCramMode();
  const router = useRouter();
  const { query, pathname } = router;
  const { communitySlug, subjectSlug } = query;

  // TODO: I know the prop drilling here is terrible
  // but need to put a pin in it for now
  const [navState, setNavState] = useState(navDefaultState);
  const toggleSidebar = () => setNavState(!navState);
  const showNavDesktop = !hideNav && !hideNavDesktop;

  const { open, handleClose, subjects, isLoading } = useSaveSubjectPaywall();
  const navWidth = useGetNavWidth();

  // if we're hiding the nav, we want to center the content on the page
  const containerClassName = hideNavDesktop ? "jcC" : className;

  const boundingPadding = calculateBoundingPadding(
    fullWidthDesktop,
    navState,
    hideNav,
    showNavDesktop,
    navWidth
  );

  return (
    <div className={containerClassName}>
      <Meta
        title={title}
        description={description}
        imgPath={imgPath}
        urlPath={urlPath}
        shouldNoIndex={shouldNoIndex}
      />
      <Script id="tidio" src="//code.tidio.co/vyhlueizlz76uyuebwcsjvm0mbdoqdmb.js" async />
      {/*
      - Moving this into Meta causes the LD-script tags to disappear when data is loaded.
      - tried removing the surrounding <Head> tag in JsonLD, and that did not work either
      - No idea why - BC Mar 13, 2024
      */}
      {schemaData?.filter(Boolean).map((schemaDataString, index) => (
        <JsonLD schemaDataString={schemaDataString} key={`json-ld-schema-${index + 1}`} />
      ))}
      <Head>
        <title>{title}</title>
        {meta.production && <script src="/files/userleap.js" />}
      </Head>

      <Box
        sx={{
          top: 0,
          position: "sticky",
          zIndex: 1003,
          display: { xs: "none", md: "block" },
        }}>
        {!hideTopNav && <TopNav hasCramMode={hasCramMode} pathname={pathname} pathParams={query} />}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "100%", ...pageStyles }}>
          <SaveSubjectPaywall
            open={open}
            handleClose={handleClose}
            subjects={subjects}
            isLoading={isLoading}
          />
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            {!hideNav && <MobileNav pathParams={query} pathname={pathname} />}
          </Box>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {showNavDesktop && (
              <SlidingNav
                navWidth={navWidth}
                navState={navState}
                toggleSidebar={toggleSidebar}
                communitySlug={communitySlug || subjectSlug}
                pathname={pathname}
                pathParams={query}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: navDefaultState ? "" : "center",
            }}>
            <Box
              sx={(theme) => ({
                paddingTop: hideNav || !navState ? {} : { xs: "16px", md: "32px" },
                padding: boundingPadding,
                transition: theme.transitions.create(["padding"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
                // assign "full-width" to child as class to ignore padding
                "& .full-width": {
                  width: "100vw",
                  position: "relative",
                  left: "50%",
                  right: "50%",
                  marginLeft: "-50vw",
                  marginRight: "-50vw",
                },
                // assign "centered" to center only this child
                "& .centered": {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                },
              })}>
              {children}
              {!hideFooter && <Footer />}
              {!hideBottomNav && (
                <BottomNav
                  pageData={pageData}
                  isSidebarOpen={navState}
                  pathname={pathname}
                  pathParams={query}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
