import React from "react";
import Head from "next/head";

interface JsonLDProps {
  schemaDataString?: string;
}

export const JsonLD = ({ schemaDataString = "" }: JsonLDProps): JSX.Element => (
  <script
    type="application/ld+json"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: schemaDataString,
    }}
  />
);

export const JsonLDGroup = ({ schemaData }: { schemaData: string[] }): JSX.Element[] =>
  schemaData
    ?.filter(Boolean)
    .map((schemaDataString) => (
      <JsonLD schemaDataString={schemaDataString} key={`json-ld-schema-${schemaDataString.slice(0, 30)}`} />
    ));

// for old pages router components - if needed.
// eslint-disable-next-line @typescript-eslint/naming-convention
const _JsonLD_backwards_compat = ({ schemaDataString = "" }: JsonLDProps): JSX.Element => (
  <Head>
    <JsonLD schemaDataString={schemaDataString} />
  </Head>
);

export default _JsonLD_backwards_compat;
