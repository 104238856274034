import { useState, useEffect, useCallback, useMemo } from "react";
import { setSubjectSelectList } from "@/store/slices/PageDataSlice";
import { useModernDispatch, useModernSelector } from "@/store/hooks";
import libraryApi from "@/store/api/graphql/library.extended.api";
import { Subject } from "@/store/api/graphql/generated/types";
import { Logger } from "@/utils/logger";

const LOGID = "fiveable:frontend:hooks:library:useGetSubjectsForDropdown";
const log = Logger(LOGID);

const useGetSubjectsForDropdown = (): {
  refetchSubjects: () => void;
  isSubjectsLoading: boolean;
  subjectList: Partial<Subject>[];
} => {
  const [isSubjectsLoading, setIsSubjectsLoading] = useState(false);
  const dispatch = useModernDispatch();

  const subjectList = useModernSelector((state) => state?.pageData?.subjectSelectList);

  const refetchSubjects = () => dispatch(setSubjectSelectList([]));

  const populateSubjects = useCallback(async () => {
    setIsSubjectsLoading(true);
    try {
      const result = await dispatch(
        libraryApi.endpoints.getSubjects.initiate(
          { filter: { active: { eq: true } }, skipUnits: true },
          { forceRefetch: true }
        )
      );
      const allSubjects = result?.data?.subjects;
      dispatch(setSubjectSelectList(allSubjects));
    } catch (error) {
      log.error(`Failed to fetch subjects:${error}`);
    } finally {
      setIsSubjectsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!subjectList?.length) {
      populateSubjects();
    }
  }, [subjectList?.length, populateSubjects]);

  return useMemo(
    () => ({
      refetchSubjects,
      isSubjectsLoading,
      subjectList,
    }),
    [refetchSubjects, isSubjectsLoading, subjectList?.length]
  );
};

export default useGetSubjectsForDropdown;
