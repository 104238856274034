import { usePathname } from "next/navigation";
import { LEFT_NAV_WIDTH_PX, DEFAULT_LEFT_NAV_WIDTH_PX } from "@hours/constants";

// Just an easy way for practice team to delineate pages we're working on
const useIsAPBio = (): {
  isAPBioPage: boolean;
  isAPBioGuidePage: boolean;
} => {
  const pathname = usePathname();
  const isAPBioPage = !!pathname?.includes("/ap-bio");
  const isAPBioGuidePage = !!pathname?.includes("/ap-bio") && !!pathname?.includes("/study-guide");
  return { isAPBioPage, isAPBioGuidePage };
};

export default useIsAPBio;

export const useGetNavWidth = (): number => {
  const { isAPBioGuidePage } = useIsAPBio();
  return isAPBioGuidePage ? LEFT_NAV_WIDTH_PX : DEFAULT_LEFT_NAV_WIDTH_PX;
};
