import React, { useState } from "react";
import { Button } from "@/components-modern/atoms/button/Button";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useGetStatsQuery } from "@/store/api/graphql/library.extended.api";
import Link from "@/components-modern/atoms/link/Link";
import Loading from "@/components/Library/UI/Loading/Loading";
import { useTrackEvent } from "@/services";
import { useSpecificTheme } from "@/hooks/useSpecificTheme";
import { Logger } from "@/utils/logger";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { navbarAnalyticsInfo } from "@/utils/analyticsConstants";

const LOGID = "fiveable:frontend:components-modern:molecules:top-nav:AllSubjectsDropdown";

const log = Logger(LOGID);

const AllSubjectsDropdown = (): JSX.Element => {
  const { trackEvent } = useTrackEvent();
  const theme = useSpecificTheme();

  const [allSubjectsAnchorEl, setAllSubjectsAnchorEl] = useState<null | HTMLElement>(null);

  const { data, isLoading, error } = useGetStatsQuery({});

  const handleAllSubjectsClick = (event: React.MouseEvent<HTMLElement>) => {
    trackEvent({ ...navbarAnalyticsInfo.NAV_ITEM_CLICK, label: "All Subjects" });

    setAllSubjectsAnchorEl(event.currentTarget);
  };

  const handleAllSubjectsClose = () => {
    setAllSubjectsAnchorEl(null);
  };

  const renderMenuItems = () => {
    if (isLoading) {
      return <Loading message="Loading subject categories..." />;
    }

    if (error) {
      log.error(error);
      return <Typography color="error">Error loading subjects</Typography>;
    }

    if (!data?.stats?.countSubjectsByCategoryBranch) {
      return <Typography>No subjects available</Typography>;
    }

    return (
      <Box>
        <MenuItem
          key="typeform"
          disableRipple
          disableTouchRipple
          sx={{
            "&:hover": { background: "none" },
            borderBottom: `1px solid ${theme.palette.divider}`,
            my: 1,
            py: 0.5,
          }}>
          <Link
            sx={{ mb: 1 }}
            variant="textSecondary"
            href="https://thinkfiveable.typeform.com/to/NMRidRka"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              handleAllSubjectsClose();
              trackEvent({
                ...navbarAnalyticsInfo.CLICK_ALL_SUBJECTS_CATEGORY_BRANCH_LINK,
                label: "Add your own class",
              });
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                Add your own class
              </Typography>
              <ChevronRightRoundedIcon sx={{ color: theme.palette.text.disabled }} />
            </Box>
          </Link>
        </MenuItem>
        {data.stats.countSubjectsByCategoryBranch.map((subject) => (
          <MenuItem
            disableRipple
            disableTouchRipple
            key={subject.slug}
            sx={{
              "&:hover": { background: "none" },
            }}>
            <Link
              variant="textSecondary"
              href={`/categories/${subject.slug}`}
              onClick={() => {
                handleAllSubjectsClose();
                trackEvent({
                  ...navbarAnalyticsInfo.CLICK_ALL_SUBJECTS_CATEGORY_BRANCH_LINK,
                  other: { ...subject },
                });
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}>
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                  {subject.name} <span style={{ fontWeight: 400 }}>({subject.count})</span>
                </Typography>
                <ChevronRightRoundedIcon sx={{ color: theme.palette.text.disabled }} />
              </Box>
            </Link>
          </MenuItem>
        ))}
      </Box>
    );
  };

  return (
    <>
      <Button
        sx={{ fontSize: "inherit" }}
        variant="text"
        onClick={handleAllSubjectsClick}
        endIcon={<ExpandMoreRoundedIcon />}>
        <Typography
          sx={{
            maxWidth: { xs: "80px", sm: "120px", md: "none" },
            display: "block",
            fontSize: "inherit",
            fontWeight: "inherit",
          }}
          noWrap>
          All Subjects
        </Typography>
      </Button>
      <Menu
        anchorEl={allSubjectsAnchorEl}
        id="all-subjects-menu"
        open={Boolean(allSubjectsAnchorEl)}
        onClose={handleAllSubjectsClose}
        PaperProps={{
          elevation: 1,
          sx: {
            border: `1px solid ${theme.palette.common.black}`,
            py: 1,
            px: 1.5,
            maxWidth: "300px",
            marginTop: "5px",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {renderMenuItems()}
      </Menu>
    </>
  );
};

export default AllSubjectsDropdown;
