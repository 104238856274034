"use client";

import { useState, useEffect } from "react";
import useProfile from "@/utils/useProfile";
import { CURRENT_SCHOOL_YEAR } from "@hours/constants";
import { useTrackEvent, useMailerLiteService, useProfileService } from "@/services";
import { addUserProperties } from "@/utils/analytics";
import { UPDATE_ROLE_INFO } from "@/utils/analyticsConstants";
import { IProfileBrowser } from "@hours/utilities";
import {
  CLICK_SAVE_SUBJECT_TO_PROFILE,
  CLICK_UNSAVE_SUBJECT_TO_PROFILE,
} from "@/utils/analyticsConstants/genericAnalyticsInfo";
import { useModernSelector } from "@/store/hooks";
import { selectActiveSubject } from "@/store/slices/TableOfContentsSlice";

export const buttonTextOptions = {
  unavailable: "",
  unstarted: "Save Subject",
  loading: "Loading...",
  success: "✅ Subject Saved",
  "already-bookmarked": "✅ Subject Saved",
};

const useSaveSubjectButton = ({
  communitySlug,
  subjectSlug,
  practiceSlug,
}: {
  communitySlug?: string;
  subjectSlug?: string;
  practiceSlug?: string;
}): {
  bookmarkSubject: () => void;
  clearSubject: () => void;
  buttonText: string;
  bookmarkState: string;
  profile: IProfileBrowser;
} => {
  const subject = useModernSelector(selectActiveSubject);
  const { profile, isProfileLoading } = useProfile();
  const { updateProfile } = useProfileService();
  const { updateSubscriptor } = useMailerLiteService();
  const { trackEvent } = useTrackEvent();

  const profileHasSubject =
    !isProfileLoading && profile?.subjectsAndExams?.[CURRENT_SCHOOL_YEAR]?.includes(subject?.id);

  const [bookmarkState, setBookmarkState] = useState("unavailable");

  const routerSubjectSlug = communitySlug || subjectSlug || practiceSlug?.[0];

  useEffect(() => {
    // because for a sec it will show last subject
    if (routerSubjectSlug && subject?.slug === routerSubjectSlug) {
      const newBookmarkState = profileHasSubject ? "already-bookmarked" : "unstarted";
      setBookmarkState(newBookmarkState);
    }
  }, [routerSubjectSlug, subject, profileHasSubject, isProfileLoading]);

  const subjectId = subject?.id;
  const currentSubIds = profile?.subjectsAndExams?.[CURRENT_SCHOOL_YEAR] || [];
  const newSubsAndExams = {
    ...profile?.subjectsAndExams,
    [CURRENT_SCHOOL_YEAR]: [...currentSubIds, subjectId],
  };

  const bookmarkSubject = () => {
    // clicked to attempt to save subject
    trackEvent({
      ...CLICK_SAVE_SUBJECT_TO_PROFILE,
      other: {
        subjectId,
        isLoggedin: !!profile,
      },
    });
    if (currentSubIds.includes(subjectId)) {
      setBookmarkState("already-bookmarked");
      return;
    }

    setBookmarkState("loading");

    // update profile in db and redux
    updateProfile(profile?._id, {
      subjectsAndExams: newSubsAndExams,
    });

    // update mailerlite user
    updateSubscriptor(profile?.email as string, {
      subjects: newSubsAndExams[CURRENT_SCHOOL_YEAR],
    });

    // update amplitude
    addUserProperties({
      "24/25 AP subjects + exams": newSubsAndExams[CURRENT_SCHOOL_YEAR],
    });

    // subject successfully updated
    trackEvent({
      ...UPDATE_ROLE_INFO,
      other: {
        [`${CURRENT_SCHOOL_YEAR}_subjects_exams`]: newSubsAndExams[CURRENT_SCHOOL_YEAR],
      },
    });

    setBookmarkState("success");
  };

  const clearSubject = () => {
    // clicked to attempt to un-save subject
    trackEvent({
      ...CLICK_UNSAVE_SUBJECT_TO_PROFILE,
      other: {
        subjectId,
        isLoggedin: !!profile,
      },
    });

    // if subject is not saved, do nothing
    if (!currentSubIds.includes(subjectId)) {
      setBookmarkState("unstarted");
      return;
    }

    setBookmarkState("loading");

    const subIdsWithoutSubject = currentSubIds.filter((subId) => subId !== subjectId);

    const subsAndExamsWithoutSubject = {
      ...profile?.subjectsAndExams,
      [CURRENT_SCHOOL_YEAR]: subIdsWithoutSubject,
    };

    // update profile in db and redux
    updateProfile(profile?._id, {
      subjectsAndExams: subsAndExamsWithoutSubject,
    });

    // update mailerlite user
    updateSubscriptor(profile?.email as string, {
      subjects: subsAndExamsWithoutSubject[CURRENT_SCHOOL_YEAR],
    });

    // update amplitude
    addUserProperties({
      "24/25 AP subjects + exams": subsAndExamsWithoutSubject[CURRENT_SCHOOL_YEAR],
    });

    // subject successfully updated
    trackEvent({
      ...UPDATE_ROLE_INFO,
      other: {
        [`${CURRENT_SCHOOL_YEAR}_subjects_exams`]: subsAndExamsWithoutSubject[CURRENT_SCHOOL_YEAR],
      },
    });

    setBookmarkState("unstarted");
  };

  return {
    bookmarkSubject,
    clearSubject,
    buttonText: buttonTextOptions[bookmarkState],
    bookmarkState,
    profile,
  };
};

export default useSaveSubjectButton;
