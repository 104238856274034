export const practiceQuestionPageAnalyticsInfo = {
  PRACTICE_QUESTION_PAGE_FEEDBACK: {
    category: "practice-question-page",
    action: "question-feedback",
  },
  CLICK_OPEN_STUDY_GUIDE_FROM_PRACTICE_QUESTION_PAGE: {
    category: "practice-question-page",
    action: "click-open-study-guide-from-practice-question-page",
  },
  CLICK_KEY_TERM_FROM_PRACTICE_QUESTION_PAGE: {
    category: "practice-question-page",
    action: "click-key-term-from-practice-question-page",
  },
  CLICK_PRACTICE_QUIZZES_FROM_PRACTICE_QUESTION_PAGE: {
    category: "practice-question-page",
    action: "click-practice-quizzes-from-practice-question-page",
  },
  CLICK_CRAM_MODE_CTA_FROM_PRACTICE_QUESTION_PAGE: {
    category: "practice-question-page",
    action: "click-cram-mode-cta-from-practice-question-page",
  },
};
