import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import MuiBox, { BoxProps as MuiBoxProps } from "@mui/material/Box";
import MuiStack from "@mui/material/Stack";
import Link from "@/components-modern/atoms/link/Link";

const platforms = [
  { href: "https://www.tiktok.com/@thinkfiveable", icon: faTiktok, height: "18px" },
  { href: "https://www.instagram.com/thinkfiveable", icon: faInstagram },
  { href: "https://www.youtube.com/thinkfiveable", icon: faYoutube },
  { href: "https://www.linkedin.com/company/fiveable", icon: faLinkedin },
];

function IconGroup({ items, sx }) {
  return (
    <MuiStack alignItems="center" direction="row" spacing={2} sx={sx}>
      {items.map(({ href, icon, height = "20px" }) => (
        <Link variant="textSecondary" key={href} href={href}>
          <FontAwesomeIcon icon={icon} style={{ height }} />
        </Link>
      ))}
    </MuiStack>
  );
}

export default function SocialIcons({ ...props }: MuiBoxProps): JSX.Element {
  return (
    <MuiBox {...props}>
      <MuiStack alignItems="baseline" direction="row" flexWrap="wrap" sx={{ my: -1 }}>
        <IconGroup items={platforms} sx={{ mr: 2, my: 1 }} />
      </MuiStack>
    </MuiBox>
  );
}
