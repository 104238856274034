import React, { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { CURRENT_SCHOOL_YEAR } from "@hours/constants";
import useProfile from "@/utils/useProfile";
import { formatSubjectsForAutocomplete } from "@/hooks/Library/useOnboardingForm";
import { Button } from "@/components-modern/atoms/button/Button";
import { CLICK_COPY_LAST_YEAR_SUBJECTS } from "@/utils/analyticsConstants";
import { useTrackEvent } from "@/services";
import useGetSubjectsForDropdown from "@/hooks/Library/useGetSubjectsForDropdown";
import SubjectsAutocompleteInput from "../Library/NewCustomEditor/SubjectsAutocompleteInput";

const buttonTextOptions = {
  notStarted: "add all my subjects from last school year",
  loading: "loading...",
  success: "✅ subjects copied",
  alreadyDone: "✅ subjects already copied",
};

// 2425 => 2324
const getPreviousSchoolYear = (currentYear) => {
  // Split the string into two parts
  const firstHalf = currentYear.slice(0, 2);
  const secondHalf = currentYear.slice(2);

  // Convert to integers and subtract 1
  const previousFirst = parseInt(firstHalf, 10) - 1;
  const previousSecond = parseInt(secondHalf, 10) - 1;

  // Format the result back into a string
  return `${previousFirst.toString().padStart(2, "0")}${previousSecond.toString().padStart(2, "0")}`;
};

const CopyButton = ({ copyOverSubjects, buttonText, hasSubjects = false, copyState }) => {
  return (
    <Button
      variant={hasSubjects ? "text" : "primary"}
      disabled={copyState !== "notStarted"}
      sx={{ mt: 1 }}
      onClick={copyOverSubjects}>
      {buttonText}
    </Button>
  );
};

const ClassSelect = ({
  showTitle = true,
  isInAuthModal = false,
  titleText = "What subjects are you interested in studying for the 24/25 school year?",
}: {
  showTitle?: boolean;
  isInAuthModal?: boolean;
  titleText?: string;
}): JSX.Element => {
  const { profile, isProfileLoading } = useProfile();
  const { trackEvent } = useTrackEvent();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newSubjects, setNewSubjects] = useState([]);
  const { subjectList, isSubjectsLoading } = useGetSubjectsForDropdown();

  const [copyState, setCopyState] = useState("notStarted");
  const [loading, setLoading] = useState(false);

  const alphabetizedSubjects = formatSubjectsForAutocomplete(subjectList);

  const formikProps = useFormikContext();

  const lastSchoolYear = getPreviousSchoolYear(CURRENT_SCHOOL_YEAR);
  const lastYearSubIds = profile?.subjectsAndExams?.[lastSchoolYear] || [];
  const currentSubIds = profile?.subjectsAndExams?.[CURRENT_SCHOOL_YEAR] || [];

  // allow users to copy last year's subjects to this year
  // we switched over abruptly, want to give a chance to restore subs easily
  const copyOverSubjects = () => {
    trackEvent({
      ...CLICK_COPY_LAST_YEAR_SUBJECTS,
      other: {
        lastYearSubIds,
        currentSubIds,
      },
    });
    // if we are clicking the button more than once, don't actually add more subjects
    // also if there are no subjects to copy, don't do anything
    if (copyState !== "notStarted" || lastYearSubIds.length === 0) {
      setCopyState("alreadyDone");
    }
    setCopyState("loading");

    // filter out subs already currently selected
    const uniqueLastYearSubIds = lastYearSubIds.filter((subId) => !currentSubIds.includes(subId));

    // if there are no new ones from last year to add, exit early
    if (uniqueLastYearSubIds.length === 0) {
      setCopyState("alreadyDone");
      return;
    }

    // get the full subject objects for the unique last year subjects
    const lastYearFullSubjects = Object.values(alphabetizedSubjects)?.filter((sub) =>
      uniqueLastYearSubIds.includes(sub.id)
    );

    // format them for the autocomplete
    const formattedLastYearSubs = lastYearFullSubjects.map((sub) => {
      return { id: sub.id, label: `${sub?.emoji} ${sub?.name}`, name: sub.name };
    });

    setNewSubjects([...newSubjects, ...formattedLastYearSubs]);
    setCopyState("success");
  };

  useEffect(() => {
    if (profile) {
      setLoading(true);

      const profileSubs = [];
      Object.values(alphabetizedSubjects).forEach((sub) => {
        if (profile?.subjectsAndExams?.[CURRENT_SCHOOL_YEAR]?.includes(sub.id)) {
          profileSubs.push({
            id: sub.id,
            label: `${sub?.emoji} ${sub?.name}`,
            name: sub.name,
          });
        }
      });

      // Filtering out dupes is nice to have but also necessary for users without cram mode at the moment due to the polling that happens a few components up in CramEventPage to check if user has Cram Mode
      // Create a Set of existing ids for fast lookup
      const newSubjectIds = new Set(newSubjects.map((subject) => subject.id));

      // Filter out subjects that are already in newSubjects
      const newUniqueProfileSubs = profileSubs.filter((subject) => !newSubjectIds.has(subject.id));

      // Update newSubjects with the new unique subjects
      setNewSubjects([...newSubjects, ...newUniqueProfileSubs]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, alphabetizedSubjects?.length]);

  useEffect(() => {
    formikProps.setFieldValue("subjects", newSubjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSubjects?.length]);

  const hasSubsForCurrentYear = profile?.subjectsAndExams?.[CURRENT_SCHOOL_YEAR]?.length > 0;

  const isSubjectAutocompleteLoading = isSubjectsLoading || isProfileLoading || loading;

  return (
    <Stack spacing={1}>
      {showTitle && (
        <Typography variant="body1">
          <strong>{titleText}</strong>
        </Typography>
      )}
      {/* don't have button to copy from last year if there is nothing from last year to copy */}
      {lastYearSubIds?.length > 0 && (
        <CopyButton
          copyOverSubjects={copyOverSubjects}
          buttonText={buttonTextOptions[copyState]}
          hasSubjects={hasSubsForCurrentYear}
          copyState={copyState}
        />
      )}
      <SubjectsAutocompleteInput
        isLoading={isSubjectAutocompleteLoading}
        newSubjects={newSubjects}
        setNewSubjects={setNewSubjects}
        initialSubjects={alphabetizedSubjects}
        placeholder={
          // don't prompt to copy subjects if
          // in auth flow
          // or if there are no subjects from last year
          isInAuthModal || !lastYearSubIds?.length
            ? "Choose one or more subjects"
            : "add your classes or copy subjects from last year"
        }
      />
    </Stack>
  );
};

export default ClassSelect;
