export const NAVIGATE_TO_CHECKOUT_PAGE = {
  category: "purchases",
  action: "navigate-to-checkout-page",
};

export const REDIRECT_TO_STRIPE_CHECKOUT = {
  category: "purchases",
  action: "redirect-to-stripe-checkout",
};

export const VIEW_PURCHASE_SUCCESS_PAGE = {
  category: "purchases",
  action: "view-purchase-success-page",
};

export const VIEW_PURCHASE_SUCCESS_MODAL = {
  category: "purchases",
  action: "view-purchase-success-modal",
};

export const CLICK_PURCHASE_SUCCESS_MODAL_BUTTON = {
  category: "purchases",
  action: "click-purchase-success-modal-button",
};

export const PURCHASE_SUCCESS = {
  category: "purchases",
  action: "purchase-success",
};

export const PURCHASE_REDEMPTION_VIEW = {
  category: "purchases",
  action: "purchase-redemption-view",
};

export const UNLOCK_PAGE_SUBSCRIPTION_CADENCE_TOGGLE = {
  category: "purchases",
  action: "toggle-subscription-cadence-unlock-page",
};

// manage subscription events

export const CLICK_OPEN_CANCEL_SUBSCRIPTION_MODAL = {
  category: "purchases",
  action: "click-open-cancel-subscription-modal",
};

export const CLICK_OPEN_RENEWAL_DISCOUNT_MODAL = {
  category: "purchases",
  action: "click-open-renewal-discount-modal",
};

export const CLICK_OPEN_CONVERT_SUBSCRIPTION_TYPE_MODAL = {
  category: "purchase",
  action: "click-open-convert-subscription-type-modal",
};
export const CONVERT_SUBSCRIPTION = {
  category: "purchase",
  action: "convert-subscription",
};
export const CLICK_CLOSE_CANCEL_SUBSCRIPTION_MODAL = {
  category: "purchases",
  action: "click-close-cancel-subscription-modal",
};

export const CLICK_CANCEL_SUBSCRIPTION = {
  category: "purchases",
  action: "click-cancel-subscription",
};

export const CANCEL_SUBSCRIPTION_SUCCESS = {
  category: "purchases",
  action: "cancel-subscription-success",
};

export const CANCEL_SUBSCRIPTION_FAILURE = {
  category: "purchases",
  action: "cancel-subscription-failure",
};

// end manage subscription events

export const CLICK_REQUEST_FINANCIAL_AID = {
  category: "purchases",
  action: "click-request-financial-aid",
};

export const CLICK_APPLY_PROMO_CODE = {
  category: "purchases",
  action: "click-apply-promo-code",
};

export const APPLY_PROMO_CODE = {
  category: "purchases",
  action: "apply-promo-code",
};

export const VIEW_PROMO_CODE_ERROR = {
  category: "purchases",
  action: "view-promo-code-error",
};

export const CLICK_EDIT_PROMO_CODE = {
  category: "purchases",
  action: "click-edit-promo-code",
};
