import { Role } from "@/store/api/graphql/generated/types";
import { IProfileBrowser, hasRole } from "@hours/utilities";
import { useSelector } from "react-redux";

interface IUseAuthenticationProps {
  participantId?: string;
  roles?: IProfileBrowser["roles"];
}

interface IUseAuthenticationReturnType {
  isSuperAdmin: boolean;
  isContentAuthor: boolean;
  isContentAdmin: boolean;
  isStudentAdmin: boolean; // student admins help with crams, and have a more limited permissions subset
  isCramHost: boolean;
}

export function useAuthentication({
  participantId,
  roles,
}: IUseAuthenticationProps = {}): IUseAuthenticationReturnType {
  const ownProfile = useSelector((state: { profile: IProfileBrowser }) => state.profile);

  // a participantId should only be provided when the user being passed in is not the current user - so if there isn't one, use the profile stored in redux
  const profile: Partial<IProfileBrowser> = participantId ? { roles } : ownProfile;

  // NOTE: superAdmin has all permissions
  const isSuperAdmin = hasRole({ profile, roleName: Role.SuperAdmin });
  const isContentAuthor = hasRole({ profile, roleName: Role.ContentAuthor });
  const isContentAdmin = hasRole({ profile, roleName: Role.ContentAdmin });
  const isStudentAdmin = hasRole({ profile, roleName: Role.StudentAdmin });
  const isCramHost = hasRole({ profile, roleName: Role.CramHost });

  return {
    isSuperAdmin,
    isContentAuthor,
    isContentAdmin,
    isStudentAdmin,
    isCramHost,
  };
}
