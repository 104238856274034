import { Box, SxProps, Typography } from "@mui/material";
import { useSpecificTheme } from "@/hooks/useSpecificTheme";

export const NewTag = ({
  containerSx = {},
  label = "wow",
}: {
  containerSx?: SxProps;
  label?: string;
}): JSX.Element => {
  const theme = useSpecificTheme();
  return (
    <Box
      sx={{
        background: theme.palette.gradients.fiveableUniverse,
        p: "4px 6.5px",
        position: "absolute",
        top: 5,
        right: -40,
        borderRadius: theme.borderRadii.LARGE,
        width: "fit-content",
        display: "flex",
        ...containerSx,
      }}>
      <Typography
        variant="overline"
        fontWeight={800}
        sx={{
          color: "white",
          fontSize: ".5rem",
          letterSpacing: ".46px",
          lineHeight: "normal",
        }}>
        {label}
      </Typography>
    </Box>
  );
};
