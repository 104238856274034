import React, { useState } from "react";
import { Box, Chip, SxProps, TextField } from "@mui/material";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete } from "@/components-modern/atoms/autocomplete/Autocomplete";
import CustomPaperForAutocomplete from "./CustomPaperForAutocomplete";

export interface CustomAutocompleteOption {
  label: string;
  name?: string;
  url?: string;
  emoji?: string;
  id?: string;
}

export interface AutocompleteMultiInputProps {
  placeholder?: string;
  label?: string;
  formattedOptions?: CustomAutocompleteOption[];
  handleChange?: (value: CustomAutocompleteOption) => void;
  name?: string;
  hasError?: boolean;
  currentValue?: CustomAutocompleteOption;
  isLoading?: boolean;
  disabled?: boolean;
  autocompleteStyles?: SxProps;
}

// eslint-disable-next-line max-lines-per-function
const CustomAutocomplete = ({
  placeholder,
  label,
  formattedOptions,
  currentValue,
  handleChange,
  hasError = false,
  isLoading = false,
  disabled = false,
  autocompleteStyles = {},
}: AutocompleteMultiInputProps): JSX.Element => {
  // This is happening manually so styles, like border-radius, can be adjusted when it's open vs closed
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  if (!formattedOptions) return null;

  const onChange = (event, val) => {
    handleChange(val);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        data-testid={`custom-autocomplete-${placeholder}`}
        disabled={disabled}
        loading={isLoading}
        // https://stackoverflow.com/questions/61348049/material-ui-autocomplete-press-enter-to-create-new-chips
        autoHighlight
        open={isPopperOpen}
        onOpen={() => setIsPopperOpen(true)}
        onClose={() => setIsPopperOpen(false)}
        placeholder={placeholder}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-error - this freaks out bc we defined as SxProps but we are using theme => ({}) so it gets confused
        sx={(theme) => ({
          width: { xs: "100%", md: "300px" },
          borderRadius: isPopperOpen ? "20px 20px 0px 0px !important" : "40px",
          "&:focus": {
            border: "2px solid black",
          },

          "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
            borderRadius: "40px",
            backgroundColor: theme.palette.background.paper,
            border: "none !important",
          },
          "&.MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused": {
            borderRadius: isPopperOpen ? "20px 20px 0px 0px !important" : "40px !important",
            border: "none !important",
          },
          "&.MuiAutocomplete-root .Mui-focused": {},
          ...autocompleteStyles,
        })}
        PaperComponent={CustomPaperForAutocomplete}
        size="small"
        onChange={onChange}
        options={formattedOptions}
        getOptionLabel={(option) => {
          return option.label;
        }}
        renderOption={(props, option) => (
          <li {...props} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            {option.label} {option.tag ? <Chip size="small" color="secondary" label={option.tag} /> : null}
          </li>
        )}
        value={currentValue}
        filterSelectedOptions
        openOnFocus
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      style={{ width: "15px", marginLeft: "5px", animation: "spin 1s linear infinite" }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                boxSizing: "border-box",
                border: "2px solid black",
                borderBottom: isPopperOpen ? "none" : "2px solid black",
                boxShadow: "none",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: hasError ? "red" : "",
              },
            }}
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default React.memo(CustomAutocomplete);
