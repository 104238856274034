import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import useOnboardingForm from "@/hooks/Library/useOnboardingForm";
import { ISubjectAutocompleteSubject } from "@/types";
import { ProfileLevel } from "@hours/utilities";
import {
  hsGraduationyearValidation,
  levelValidation,
  subjectsValidation,
} from "../../lib/FormValidation/User";
import OnboardingQuestions from "./OnboardingQuestions";

export interface SignupFormValues {
  subjects: ISubjectAutocompleteSubject[];
  hsGraduationYear?: string | null;
  level?: ProfileLevel;
}

interface SSOFollowupFormProps {
  loginWithThirdParty?: (provider: string, subjects: ISubjectAutocompleteSubject[]) => () => void;
  onClick?: () => void;
  customSubmitFn?: (values: {
    subjects: ISubjectAutocompleteSubject[] | null;
    hsGraduationYear?: string | null;
    invoiceId?: string;
    level?: ProfileLevel;
  }) => void;
  buttonLabel?: string;
  source?: "sso" | "cramsDashboard" | "myStuffDropdown";
  hasBackButton?: string;
}

// eslint-disable-next-line max-lines-per-function
export default function SSOFollowupForm({
  hasBackButton,
  onClick,
  buttonLabel,
  loginWithThirdParty,
  customSubmitFn,
  source = "sso",
}: SSOFollowupFormProps): JSX.Element {
  const { onSubmit } = useOnboardingForm({ source, loginWithThirdParty, onCancel: onClick });

  const validationSchema = Yup.object().shape({
    subjects: subjectsValidation,
    level: levelValidation,
    hsGraduationYear: hsGraduationyearValidation,
  });

  const initialLibraryValues = {
    subjects: [],
    hsGraduationYear: null,
    level: "",
  };

  const onFormSubmit = (values) => {
    if (customSubmitFn) customSubmitFn(values);
    onSubmit(values);
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialLibraryValues} onSubmit={onFormSubmit}>
      <Form>
        <OnboardingQuestions hasBackButton={hasBackButton} buttonLabel={buttonLabel} />
      </Form>
    </Formik>
  );
}
