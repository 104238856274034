// methods: https://developers.tidio.com/docs/widget-other-methods#popup-open
// TODO: use their eslint / ts-config setup instead
declare global {
  interface Window {
    tidioChatApi: {
      open: () => void;
      close: () => void;
      hide: () => void;
      show: () => void;
      messageFromVisitor: (message: string) => void;
    };
  }
}

const isSupportWidgetMounted = () => typeof window !== "undefined" && window.tidioChatApi;

// Actually makes the chat icon in the corner appear / disappear
export const hideSupportChat = (): void => isSupportWidgetMounted() && window.tidioChatApi?.hide?.();
export const showSupportChat = (): void => isSupportWidgetMounted() && window.tidioChatApi?.show?.();

// Opens and closes the chat UI
export const openSupportChat = (): void => {
  if (isSupportWidgetMounted()) {
    showSupportChat();
    window.tidioChatApi?.open?.();
  }
};
export const closeSupportChat = (): void => isSupportWidgetMounted() && window.tidioChatApi?.close?.();

export const openSupportChatWithMessage = (userMessage: string): void => {
  if (!isSupportWidgetMounted()) {
    return;
  }

  // open help widget in bottom right corner
  window.tidioChatApi?.messageFromVisitor(userMessage);
};
