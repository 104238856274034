import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useStyles from "./Loading.styles";

interface LoadingProps {
  message: string;
  fullscreen?: boolean;
}

const Loading = ({ message, fullscreen = true }: LoadingProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { classes } = useStyles({ fullscreen });

  return (
    <div className={classes.container}>
      <CircularProgress size={50} />
      {message ? <p className={classes.message}>{message}</p> : null}
    </div>
  );
};

export default Loading;
