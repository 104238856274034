import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useFormikContext, useField } from "formik";
import useProfile from "@/utils/useProfile";
import { generateNumberArray } from "@/utils/helperfunctions";
import { Select } from "@/components-modern/atoms/select/Select";

const HS_QUESTION_TEXT = "What year do you graduate?";

const gradYearOptions = generateNumberArray(1970, 2030, -1);

type ThemeWithError = Theme & {
  palette: {
    text: {
      error: string;
    };
  };
};

const GraduationYearSelect = (): JSX.Element => {
  const { profile } = useProfile();

  const formikProps = useFormikContext();
  const [hsYear, setHsYear] = useState(profile?.hsGraduationYear);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hsYearField, hsYearMeta] = useField("hsGraduationYear");
  const hasError = hsYearMeta.error && hsYearMeta.touched;

  useEffect(() => {
    formikProps.setFieldValue("hsGraduationYear", profile?.hsGraduationYear || null);
  }, [profile?.hsGraduationYear]);

  const handleChange = (e) => {
    setHsYear(e.target.value);
    formikProps.setFieldValue("hsGraduationYear", e.target.value);
  };

  return (
    <div>
      <Typography variant="body1" mb={1}>
        <strong>{HS_QUESTION_TEXT}</strong>
      </Typography>
      <Select
        fullWidth
        value={hsYear}
        placeholder="Choose a graduation year"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: hasError ? "red" : "",
          },
        }}
        onChange={handleChange}
        options={gradYearOptions.map((year) => {
          return {
            key: `year-${year}`,
            value: year,
            label: year,
          };
        })}
      />

      {hasError && (
        <Typography
          sx={(theme: ThemeWithError) => ({
            pl: 2,
            mt: 1.5,
            color: theme.palette.error.main,
            fontSize: ".75rem",
          })}>
          You must select a graduation year.
        </Typography>
      )}
    </div>
  );
};

export default GraduationYearSelect;
