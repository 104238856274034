export const practiceModeAnalyticsInfo = {
  PRACTICE_QUESTION_SET_COMPLETE: {
    category: "practice-questions",
    action: "practice-question-set-complete",
  },
  HIDE_PRACTICE_QUIZ_MOBILE: {
    category: "practice-questions",
    action: "hide-practice-quiz-mobile",
  },
  SHOW_PRACTICE_QUIZ_MOBILE: {
    category: "practice-questions",
    action: "show-practice-quiz-mobile",
  },
  VIEW_PRACTICE_QUESTIONS: {
    category: "practice-questions",
    action: "view-practice-questions",
  },
  GENERATE_QUESTIONS: {
    category: "practice-mode",
    action: "generate-questions",
  },
  SELECT_NUMBER_OF_QUESTIONS: {
    category: "practice-mode",
    action: "select-number-of-questions",
  },
  SELECT_SUBJECT: {
    category: "practice-mode",
    action: "select-subject",
  },
  SELECT_UNIT: {
    category: "practice-mode",
    action: "select-unit",
  },
  SELECT_TOPIC: {
    category: "practice-mode",
    action: "select-topic",
  },
  SELECT_DIFFICULTY: {
    category: "practice-mode",
    action: "select-difficulty",
  },
  CLICK_RELATED_CONTENT_LINK: {
    category: "practice-questions",
    action: "click-related-content-link",
  },
  RESET_PRACTICE_QUESTIONS: {
    category: "practice-questions",
    action: "reset-practice-questions",
  },
  REVIEW_PRACTICE_QUESTIONS: {
    category: "practice-questions",
    action: "review-practice-questions",
  },
  CRAMS_MODE_PRACTICE_GATE_OPEN: {
    category: "crams-mode-practice-gate",
    action: "open-practice-gate",
  },
  CRAMS_MODE_PRACTICE_GATE_CLOSE: {
    category: "crams-mode-practice-gate",
    action: "close-practice-gate",
  },
  CRAMS_MODE_PRACTICE_GATE_CLOSE_FAILED: {
    category: "crams-mode-practice-gate",
    action: "attempt-close-practice-gate",
  },
  CRAMS_MODE_GATE_BUY_SEASON_PASS: {
    category: "crams-mode-practice-gate",
    action: "click-buy-crams-season-pass",
  },
  // this is for the pep quiz, keeping this name for consistency in Amplitude
  UNDERSTANDING_CHECK_COMPLETE: {
    category: "practice-questions",
    action: "understanding-check-complete",
  },
  VIEW_CHECK_FOR_UNDERSTANDING: {
    category: "practice-questions",
    action: "view-check-for-understanding",
  },
  CLICK_PRACTICE_FROM_PEP_QUIZ: {
    category: "practice-questions",
    action: "click-practice-from-pep-quiz",
  },
  VIEW_DIFFICULTY_PAYWALL: {
    category: "practice-questions",
    action: "view-difficulty-paywall",
  },
  DIFFICULTY_BUY_SEASON_PASS: {
    category: "practice-questions",
    action: "click-difficulty-buy-season-pass",
  },
  // PQ Feedback Analytics Info
  PRACTICE_QUESTION_FEEDBACK: {
    category: "practice-questions",
    action: "practice-questions-feedback",
  },
  // WALK ME THROUGH
  WALK_ME_THROUGH_QUESTION_SET_COMPLETE: {
    category: "practice-questions",
    action: "walk-me-through-question-set-complete",
  },
  WALK_ME_THROUGH_ANSWER_QUESTION: {
    category: "practice-questions",
    action: "walk-me-through-answer-question",
  },
  CLICK_VIEW_WALK_ME_THROUGH: {
    category: "practice-questions",
    action: "click-view-walk-me-through",
  },
  VIEW_WALK_ME_THROUGH_PAYWALL: {
    category: "practice-questions",
    action: "view-walk-me-through-paywall",
  },
  VIEW_WALK_ME_THROUGH_QUESTION_SET: {
    category: "practice-questions",
    action: "view-walk-me-through-question-set",
  },
  VIEW_WALK_ME_THROUGH_CONTEXT_SCREEN: {
    category: "practice-questions",
    action: "view-walk-me-through-context",
  },
  VIEW_WALK_ME_THROUGH_COMPLETION_SCREEN: {
    category: "practice-questions",
    action: "view-walk-me-through-context",
  },
  WALK_ME_THROUGH_FEEDBACK: {
    category: "practice-questions",
    action: "walk-me-through-feedback",
  },
  // PQ POPUP
  VIEW_PRACTICE_QUESTION_POPUP: {
    category: "practice-questions",
    action: "view-practice-question-popup",
  },
  CLICK_PRACTICE_FROM_PQ_POPUP: {
    category: "practice-questions",
    action: "click-practice-from-pq-popup",
  },
  CLICK_SUBJECT_PAGE_UNIT_QUIZ: {
    category: "practice-questions",
    action: "click-unit-quiz-from-subject-page",
  },
  CLICK_UNIT_PAGE_UNIT_QUIZ: {
    category: "practice-questions",
    action: "click-unit-quiz-from-unit-page",
  },
  CLICK_UNIT_PAGE_TOPIC_QUIZ: {
    category: "practice-questions",
    action: "click-topic-quiz-from-unit-page",
  },
  CLICK_PQ_TREE_LINK: {
    category: "practice-questions",
    action: "click-link-in-pq-tree",
  },
  VIEW_AP_STYLE_QUESTION: {
    category: "practice-questions",
    action: "view-ap-style-question",
  },
  CLICK_TIMER_BUTTON: {
    category: "practice-questions",
    action: "click-timer-button",
  },
  TIMER_FINISHED: {
    category: "practice-questions",
    action: "timer-finished",
  },
  CLICK_VIEW_PRACTICE_QUESTION_PAGE: {
    category: "practice-questions",
    action: "click-view-practice-question-page",
  },
  CLICK_SIDEBAR_PRACTICE_PAYWALL_CTA: {
    category: "practice-questions",
    action: "click-sidebar-practice-paywall-cta",
  },
  VIEW_SIDEBAR_PRACTICE_PAYWALL: {
    category: "practice-questions",
    action: "view-sidebar-practice-paywall",
  },
};
