/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Stack, FormControlLabel, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import useLocalStorage from "@/hooks/Library/useLocalStorage";
import { useTrackEvent } from "@/services";
import { navbarAnalyticsInfo } from "@/utils/analyticsConstants";
import useProfile from "@/utils/useProfile";
import { setAnalyticsUser } from "@/utils/analytics";
import { Switch } from "@/components-modern/atoms/switch/Switch";

const ThemeModeSwitch = () => {
  const themeMode = useSelector((state) => state.themeMode);
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const { trackEvent } = useTrackEvent();
  const [savedMode, setSavedMode] = useLocalStorage("fiveable-theme-mode", "light");
  const [mode, setMode] = useState(themeMode);

  const handleClick = () => {
    // TODO - code cleanup: create constants or boolean for theme modes
    const m = mode === "dark" ? "light" : "dark";
    setMode(m);
    setSavedMode(m);
    // we are not relying on the redux profile for updating the user theme in amplitude, this fn draws it from localStorage at time of invocation, but this fn expects the profile object so passing it in anyway
    setAnalyticsUser(profile);
    trackEvent({
      ...navbarAnalyticsInfo.TOGGLE_THEME_MODE,
      other: {
        newMode: m,
      },
    });
    setTimeout(() => {
      dispatch({ type: "UPDATE_LIBRARY_THEME_MODE", payload: { theme: m } });
    }, 450);
  };

  useEffect(() => {
    if (savedMode !== themeMode) {
      dispatch({ type: "UPDATE_LIBRARY_THEME_MODE", payload: { theme: savedMode } });
      setMode(savedMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedMode]);

  return (
    <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
      <FormControlLabel
        control={
          <Switch
            checked={mode === "dark"}
            size={"medium"}
            onChange={handleClick}
            inputProps={{
              "aria-label": `Switch to ${mode === "dark" ? "Light" : "Dark"} mode`,
            }}
          />
        }
        label={<Typography variant="body2">{mode === "dark" ? "Dark" : "Light"}</Typography>}
        sx={{ color: "text.primary", margin: `0` }}
        labelPlacement={"start"}
      />
    </Stack>
  );
};

export default ThemeModeSwitch;
