import React from "react";
import Link, { LinkVariant } from "@/components-modern/atoms/link/Link";
import { Button } from "@/components-modern/atoms/button/Button";
import { Box } from "@mui/material";

// Make it look like an MUI ButtonGroup but with links
// the actual ButtonGroup does not respect our link styles
const getLinkBorderRadius = (
  index: number,
  length: number
):
  | {
      borderTopRightRadius?: number;
      borderBottomRightRadius?: number;
      borderTopLeftRadius?: number;
      borderBottomLeftRadius?: number;
      borderRadius?: number;
    }
  | Record<string, never> => {
  if (length === 1) return {};
  const isFirst = index === 0;
  const isLast = index === length - 1;

  if (isFirst) {
    return {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    };
  }

  if (isLast) {
    return {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    };
  }

  return {
    borderRadius: 0,
  };
};

interface LinkGroupProps {
  links: { href: string; label: string; id: string }[];
  onClick?: (
    link: { href: string; label: string; id: string },
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  linkStyles?: React.CSSProperties;
  linkVariant?: LinkVariant;
}

const LinkGroup = ({
  links,
  onClick,
  linkStyles = {},
  linkVariant = "primary",
}: LinkGroupProps): JSX.Element => {
  // if is actually a button, would have onClick and not href
  // these may be grouped together here
  // but we still want to filter out if neither exist
  // for example if practice questions don't exist for subject, will be {}
  const fullLinks = links?.filter((link) => (link.href || onClick) && link.label && link.id);

  if (!fullLinks?.length) return null;

  return (
    <Box>
      {fullLinks.map((link, index) => {
        const borderRadius = getLinkBorderRadius(index, fullLinks.length);
        if (!link.href)
          return (
            <Button
              key={link.label}
              variant={linkVariant}
              onClick={(e) => onClick?.(link, e)}
              sx={{ verticalAlign: "unset", ...borderRadius, ...linkStyles }}>
              {link.label}
            </Button>
          );
        return (
          <Link
            id={link.id}
            name={link.label}
            href={link.href}
            variant={linkVariant}
            key={link.label}
            onClick={(e) => {
              onClick?.(link, e);
            }}
            linkStyles={{ margin: 0, ...borderRadius, ...linkStyles }}>
            {link.label}
          </Link>
        );
      })}
    </Box>
  );
};

export default LinkGroup;
