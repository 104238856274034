import React, { useState } from "react";
import {
  Box,
  SelectProps as MuiSelectProps,
  Select as MuiSelect,
  SelectChangeEvent,
  MenuItem as MuiMenuItem,
  Typography,
} from "@mui/material";
import Image from "next/legacy/image";
import { useSpecificTheme } from "@/hooks/useSpecificTheme";

type SelectOption = {
  value: string | number;
  label: string;
  key: string;
  placeholder?: boolean;
  icon?: string;
  iconAlt?: string;
  backgroundColor?: string;
};

export type Props = MuiSelectProps & {
  value?: string;
  options: SelectOption[];
  placeholder?: string;
  onChange: (event: SelectChangeEvent) => void;
};

// TODO: The select dropdown menu width should be the same width as the input.
export const Select = ({ ...props }: Props): JSX.Element => {
  const specificTheme = useSpecificTheme();
  const [value, setValue] = useState(props.value || "");

  const handleChange = (event) => {
    const val = event.target.value;
    setValue(val);
    props.onChange(event);
  };

  return (
    <MuiSelect
      {...props}
      value={value}
      displayEmpty
      onChange={handleChange}
      sx={{ background: specificTheme.palette.background.paper, ...(props.sx || {}) }}
      renderValue={
        value
          ? undefined
          : () => (
              <Typography variant="body2" sx={{ color: "text.disabled" }}>
                {props.placeholder}
              </Typography>
            )
      }>
      {props.options.map((option) => (
        <MuiMenuItem value={option.value} key={option.key} sx={{ wordBreak: "break-all" }}>
          {(option.icon && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: (theme) => theme.palette.common.black,
              }}>
              {option.icon && (
                <Image alt={option.iconAlt} src={`/img/icons/${option.icon}`} width={15} height={15} />
              )}
              <Box sx={{ display: "inline-block", marginLeft: "4px" }}>{option.label}</Box>
            </Box>
          )) ??
            option.label}
        </MuiMenuItem>
      ))}
    </MuiSelect>
  );
};
