import React from "react";
import { Paper } from "@mui/material";

function CustomPaperForAutocomplete({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <Paper
      sx={(theme) => ({
        bgcolor: "transparent",
        border: "none",
        boxShadow: "none",
        borderRadius: "20px",
        borderTop: "none",
        "& .MuiAutocomplete-noOptions": {
          borderTopRightRadius: "0px !important",
          borderTopLeftRadius: "0px !important",
          bgcolor: theme.palette.background.paper,
          boxSizing: "border-box",
          borderRadius: "20px",
          border: "2px solid black",
          borderTop: "none",
        },
        "& .MuiAutocomplete-listbox": {
          borderTopRightRadius: "0px !important",
          borderTopLeftRadius: "0px !important",
          bgcolor: theme.palette.background.paper,
          boxSizing: "border-box",
          borderRadius: "20px",
          border: "2px solid black",
          borderTop: "none",
        },
      })}>
      {children}
    </Paper>
  );
}

export default CustomPaperForAutocomplete;
