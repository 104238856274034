import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { Stack } from "@mui/material";
import ThemeModeSwitch from "@/components/Library/Design/ThemeModeSwitch";

import AvatarLoginSignupButtons from "@/components/Layouts/NewNavbar/AvatarLoginSignupButtons";
import useProfile from "@/utils/useProfile";
import { ComponentType, PageType, useSimpleCtaExperiment } from "@/hooks/useSimpleCtaExperiment";
import MyStuffDropdown from "./MyStuffDropdown";
import AllSubjectsDropdown from "./AllSubjectsDropdown";

const LogoLink = dynamic(() => import("@/components/Layouts/NewNavbar/LogoLink"), { ssr: false });

const GuidesLeftBanner = dynamic(() => import("@/components/Layouts/NewNavbar/GuidesLeftBanner"), {
  ssr: false,
});

const TopNav = ({
  hasCramMode = true,
  pathname = "",
  pathParams = {},
}: {
  hasCramMode: boolean;
  pathname: string;
  pathParams:
    | Record<string, string | string[] | undefined>
    | { [key: string]: string | string[] | undefined };
}): JSX.Element => {
  const { profile } = useProfile();
  const [showLoginSignup, setShowLoginSignup] = useState(false);

  useEffect(() => {
    // if (!router.isReady) return;
    // If we are on the sign up or log in pages, don't include login/signup modal links
    if (["/sign-up", "/login"].includes(pathname)) {
      setShowLoginSignup(false);
    } else {
      setShowLoginSignup(true);
    }
  }, [pathname]);

  return (
    <Stack
      id="top-nav"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      gap={3}
      sx={{
        py: 1,
        height: "65px",
        bgcolor: "background.paper",
        zIndex: 999999,
      }}>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <LogoLink />
        {useSimpleCtaExperiment(ComponentType.TOP_NAV_CTA, PageType.GENERAL) && !hasCramMode && (
          <GuidesLeftBanner />
        )}
      </Stack>
      <Stack
        alignItems="center"
        flexDirection="row"
        gap={2}
        sx={{
          width: "fit-content",
          maxWidth: "100%",
        }}>
        <AllSubjectsDropdown />
        {(profile && <MyStuffDropdown />) ?? null}
        {showLoginSignup ? (
          <AvatarLoginSignupButtons profile={profile} pathname={pathname} pathParams={pathParams} />
        ) : null}
        <ThemeModeSwitch />
      </Stack>
    </Stack>
  );
};

export default React.memo(TopNav);
