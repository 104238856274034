import React from "react";
import MuiStack from "@mui/material/Stack";
import MuiIconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useSwiper } from "swiper/react";
import { usePathname, useRouter } from "next/navigation";
import useUpdateQueryParams from "@/hooks/useUpdateQueryParams";
import { Button } from "@/components-modern/atoms/button/Button";
import { useField } from "formik";
import { ProfileLevel } from "@hours/utilities";
import GraduationYearSelect from "./GraduationYearSelect";
import ClassSelect from "./ClassSelect";
import LevelSelect from "./LevelSelect";

// eslint-disable-next-line max-lines-per-function
const OnboardingQuestions = ({
  hasBackButton,
  buttonLabel = "Sign up",
}: {
  hasBackButton?: boolean | string;
  buttonLabel?: string;
}): JSX.Element => {
  const router = useRouter();
  const pathname = usePathname();
  const { removeQueryParamFromUrl } = useUpdateQueryParams();
  const [levelField] = useField("level");
  const shouldShowGradYearSelect = ![
    ProfileLevel.OTHER,
    ProfileLevel.COLLEGE_PROF,
    ProfileLevel.HS_TEACHER,
  ].includes(levelField.value);

  const swiper = useSwiper();
  const clickPrev = () => {
    return swiper.slidePrev();
  };

  // TODO: Before we implemented node analytics tracking, these questions followed the redirect from google and so we used query params to show the modal or not. We no longer need to do this, but until it's fixed, technically this opens in a new modal controlled by these params so if we want to go "back" we have to clear them.
  const clearSSO = () => {
    const url = removeQueryParamFromUrl(pathname ?? "", ["apsignup", "thirdParty"]);
    router.push(url);
  };

  return (
    <MuiStack gap={2}>
      {hasBackButton && (
        <MuiIconButton
          sx={{ width: "30px" }}
          aria-label="back"
          onClick={hasBackButton === "sso" ? clearSSO : clickPrev}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ width: "16px", height: "16px", justifyContent: "flex-start" }}
          />
        </MuiIconButton>
      )}
      <LevelSelect />
      <ClassSelect isInAuthModal={!!hasBackButton} />
      {shouldShowGradYearSelect && <GraduationYearSelect />}
      <Button fullWidth variant="secondary" type="submit">
        {buttonLabel}
      </Button>
    </MuiStack>
  );
};

export default OnboardingQuestions;
