import { ReadonlyURLSearchParams } from "next/navigation";

const getUrlWithQueryParams = (relativePath: string, params: ReadonlyURLSearchParams) => {
  const url = new URL(relativePath, process.env.NEXT_PUBLIC_LIBRARY_BASE_URL || "http://localhost:3000");
  if (params?.toString().length) {
    return `${url.toString()}?${params.toString()}`;
  }

  return url.toString();
};

// function throwError(message) {
//   const error = new Error(message);
//   error.name = "useReadingPracticeNavBar";
//   return error;
// }

export const NUM_PRACTICE_QUESTIONS = 5;
const ADDITIONAL_RESOURCES_REGEX =
  /(blog|slides|watch|study-tools|faqs|exam-skills|previous-exam-prep|crams-2021|crams-2022)/;

// since there are more than two options (reading, practice, and ap style practice) we can no longer use simple if/else
// this is because there are too many cominbinations of input url to output url
// this function doesn't care which type of url is the input, it will spit out same data
const getDataFromUrl = ({ pathParams, pathname }) => {
  let subject: string;
  let unit: string;
  let isUnitOverviewPage: boolean;
  let isAdditionalResourcesPage: boolean;
  let unitRegex: RegExp;
  let topicId: string;

  // encompasses both types of practice since structure is same
  const isPracticeUrl = !!pathParams?.slug;

  // is content, subject, or unit page
  if (!isPracticeUrl) {
    const { communitySlug, unitSlug } = pathParams;
    subject = Array.isArray(communitySlug) ? communitySlug[0] : communitySlug;
    unit = Array.isArray(unitSlug) ? unitSlug[0] : unitSlug;
    isUnitOverviewPage = pathname?.includes("overview");
    isAdditionalResourcesPage = pathname?.match(ADDITIONAL_RESOURCES_REGEX);
    unitRegex = /unit-([1-9])\/?/;
    const unitMatch = pathname?.match(unitRegex);
    unit = unitMatch?.[0];
    // this is to account for non-numeric units that are legit, like ACT english for example
    // exludes slugs which are not real "units" per se, like faqs (which makes these additional resources pages)
    if (!unit && !isAdditionalResourcesPage) {
      unit = unitSlug;
    }
    // is practice style page
  } else {
    const { slug } = pathParams;
    const slugArray: string[] = Array.isArray(slug) ? slug : [slug];
    const urlComponents = slugArray.filter((component) => component !== null && component !== "all");
    const isSecondParamAUnit = Number.isNaN(+urlComponents[1]);

    subject = urlComponents?.[0];
    unit = isSecondParamAUnit ? urlComponents?.[1] : "";
    topicId = urlComponents?.[2];
  }

  return {
    subject,
    unit,
    isUnitOverviewPage,
    isAdditionalResourcesPage,
    unitRegex,
    topicId,
  };
};

// assemble data into a url for practice or ap style practice pages
const assemblePracticeUrl = ({ urlData, base, searchParams }) => {
  let path = `/${base}`;
  const { subject, unit, isAdditionalResourcesPage, topicId, isUnitOverviewPage } = urlData;

  if (subject) {
    path += `/${subject}`;
  }

  // as of 3/26/24, in "endless mode" users can only select a subject for ap-style questions, so return early
  // and now in exam mode there will be fixed amount of questions
  if (base.includes("ap-style-practice")) {
    return getUrlWithQueryParams(path, searchParams);
  }

  if (unit) {
    path += `/${unit}`;
  }

  if (topicId && !isUnitOverviewPage && !isAdditionalResourcesPage) {
    path += `/${topicId}`;
  }

  path += `/${NUM_PRACTICE_QUESTIONS}`;

  return getUrlWithQueryParams(path, searchParams);
};

// assemble data into a url for content, subject or unit pages
const assembleReadingUrl = ({ urlData, searchParams }) => {
  const { subject, unit, contentSlug, contentId } = urlData;
  let path = "/";
  if (subject) {
    path += subject;
  }
  if (unit) {
    path += `/${unit}`;
  }
  if (contentSlug && contentId) {
    path += `/${contentSlug}/study-guide/${contentId}`;
  }

  return getUrlWithQueryParams(path, searchParams);
};

export const generateTabUrl = ({
  pathParams,
  searchParams,
  pathname,
  contentSlug,
  contentId,
  topicId,
  generatedUrlType,
}: {
  topicId?: string;
  contentSlug?: string;
  contentId?: string;
  generatedUrlType: string;
  pathname?: string | null;
  pathParams?: Record<string, string | string[]> | { [key: string]: string | string[] };
  searchParams?: ReadonlyURLSearchParams | null;
}): string => {
  let path = "/";

  const newData = getDataFromUrl({ pathParams, pathname });
  const urlData = { ...newData, contentSlug, contentId, topicId: newData?.topicId ?? topicId };
  // either useSearchParams or get searchParams from router.query
  if (generatedUrlType === "reading") {
    path = assembleReadingUrl({ urlData, searchParams });
  } else if (generatedUrlType === "practice") {
    path = assemblePracticeUrl({ urlData, searchParams, base: "practice" });
  } else if (generatedUrlType === "ap-style-practice") {
    path = assemblePracticeUrl({ urlData, searchParams, base: "ap-style-practice/exams" });
  }

  // sometimes double slashes sneak in, this removes all but the ones after https:
  const cleanPath = path.replace(/(https?:\/\/)|(\/+)/g, (match, p1) => p1 || "/");

  return cleanPath;
};
