import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  container: (props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    top: props.fullscreen ? 0 : "50%",
    [theme.breakpoints.down("xs")]: {
      top: props.fullscreen ? 0 : "600%",
      left: props.fullscreen ? 0 : "50%",
      right: props.fullscreen ? 0 : "50%",
    },
    left: props.fullscreen ? 0 : "50%",
    right: props.fullscreen ? 0 : null,
    bottom: props.fullscreen ? 0 : null,
  }),
  message: {
    marginTop: theme.spacing(2),
    fontSize: "1.5em",
    fontWeight: "300",
    textAlign: "center",
    padding: theme.spacing(1.5),
    color: theme.palette.secondary.main,
  },
}));
