import dynamic from "next/dynamic";
import React from "react";
import { Typography } from "@mui/material";
import { Button } from "@/components-modern/atoms/button/Button";
import useSaveSubjectButton from "@/hooks/Library/useSaveSubjectButton";
import { IProfileBrowser } from "@hours/utilities";
import ClassSelectModal from "@/components-modern/molecules/class-select-modal/ClassSelectModal";
import { useAuthModals } from "../../../hooks/modals/useAuthModals";

const getSubjectSlug = (pathParams) => {
  const { communitySlug, subjectSlug, slug } = pathParams;
  return communitySlug || subjectSlug || slug?.[0];
};

export const SaveSubjectButton = ({
  buttonText,
  bookmarkSubject,
  clearSubject,
  bookmarkState,
  profile,
  launchSignupModal,
}: {
  buttonText: string;
  bookmarkSubject: () => void;
  clearSubject: () => void;
  launchSignupModal: ({ type, label }: { type: string; label?: string }) => void;
  bookmarkState: string;
  profile: IProfileBrowser;
}): JSX.Element | null => {
  if (bookmarkState === "unstarted") {
    // add some margin to sides for mobile only since this lives in the top nav in that case
    const onClick = profile ? bookmarkSubject : () => launchSignupModal({ type: "cheatsheet" });
    return (
      <Button variant="primary" sx={{ width: "fit-content", mx: { xs: 1, md: 0 } }} onClick={onClick}>
        {buttonText}
      </Button>
    );
  }
  if (bookmarkState === "success" || bookmarkState === "already-bookmarked") {
    return (
      <Button variant="contained" sx={{ width: "fit-content" }} onClick={clearSubject}>
        Un-save subject
      </Button>
    );
  }
  return <Typography variant="caption">{buttonText}</Typography>;
};

type WrappedSaveSubjectButtonProps = {
  pathParams: Record<string, string | string[]> | { [key: string]: string | string[] };
};

const WrappedSaveSubjectButton = ({ pathParams }: WrappedSaveSubjectButtonProps): JSX.Element | null => {
  const { showExpandedSignupModal } = useAuthModals();
  const { communitySlug, subjectSlug, slug } = pathParams;
  const { bookmarkSubject, clearSubject, buttonText, bookmarkState, profile } = useSaveSubjectButton({
    communitySlug: communitySlug as string,
    subjectSlug: subjectSlug as string,
    practiceSlug: Array.isArray(slug) ? (slug[0] as string) : (slug as string),
  });

  // subject in modernStore does not align with current slug
  // probably not on a subject-scoped page
  if (bookmarkState === "unavailable") return null;
  // NOTE: this component itself contains a version of a "save subject" button
  // however within the ClassSelectModal it's function is to launch the modal, not save a subject
  if (!profile) return <ClassSelectModal subjectSlug={getSubjectSlug(pathParams)} />;

  return (
    <SaveSubjectButton
      bookmarkSubject={bookmarkSubject}
      clearSubject={clearSubject}
      buttonText={buttonText}
      bookmarkState={bookmarkState}
      profile={profile}
      launchSignupModal={showExpandedSignupModal}
    />
  );
};

export default dynamic(() => Promise.resolve(WrappedSaveSubjectButton), { ssr: false });
