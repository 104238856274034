/* turns unit-3-land-based-empires into unit-3 */
export const unitSlugRegex = (rawSlug: string): string => {
  let unitSlug = rawSlug;
  try {
    const regex = /(?<unitSlug>unit-\d{1,2})/gm;
    const lookup = regex.exec(rawSlug);
    if (lookup?.groups?.unitSlug) unitSlug = lookup.groups.unitSlug;
  } catch {
    // do nothing
  }
  return unitSlug;
};
