export const cramQuizAnalyticsInfo = {
  CRAM_QUIZ_ERROR: {
    category: "cram-quiz",
    action: "cram-quiz-error",
  },
  CRAM_QUIZ_STATE_RESET: {
    category: "cram-quiz",
    action: "cram-quiz-state-reset",
  },
  CRAM_QUIZ_SHOW_ANSWERS: {
    category: "cram-quiz",
    action: "cram-quiz-show-answers",
  },
  CRAM_QUIZ_QUESTION_CHANGED: {
    category: "cram-quiz",
    action: "cram-quiz-question-changed",
  },
  CRAM_QUIZ_EMOJI_REACTION: {
    category: "cram-quiz",
    action: "cram-quiz-emoji-reaction",
  },
};
