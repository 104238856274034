import React, { Suspense } from "react";
import { Box, Typography } from "@mui/material";
import { Button } from "@/components-modern/atoms/button/Button";
import { faChevronsLeft, faChevronsRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavContent from "./NavContent";

const SlidingNav = ({
  navState,
  toggleSidebar,
  pathname = "",
  pathParams = {},
  communitySlug = "",
  navWidth = 350,
}: {
  navState: boolean;
  toggleSidebar: () => void;
  pathname: string;
  pathParams:
    | Record<string, string | string[] | undefined>
    | { [key: string]: string | string[] | undefined };
  communitySlug: string | string[] | undefined;
  navWidth?: number;
}): JSX.Element => (
  <Box
    sx={(theme) => ({
      width: `${navWidth}px`,
      display: "flex",
      position: "relative",
      zIndex: 1001,
      backgroundColor: theme.palette.background.paper,
    })}>
    <Suspense fallback={null}>
      {!navState ? (
        <Button onClick={toggleSidebar} variant="contained" sx={{ position: "fixed", m: 1 }} size="small">
          <Typography variant="caption">expand</Typography>
          <FontAwesomeIcon
            icon={faChevronsRight}
            style={{ width: ".875rem", color: "inherit", marginRight: "8px", marginLeft: "8px" }}
          />
        </Button>
      ) : (
        <Button
          onClick={toggleSidebar}
          variant="contained"
          sx={{ position: "fixed", m: 1, zIndex: 100 }}
          size="small">
          <Typography variant="caption">collapse</Typography>
          <FontAwesomeIcon
            icon={faChevronsLeft}
            style={{ width: ".875rem", color: "inherit", marginRight: "8px", marginLeft: "8px" }}
          />
        </Button>
      )}
      <Box
        sx={(theme) => ({
          position: "fixed",
          height: "100%",
          width: `${navWidth}px`,
          transition: theme.transitions.create("transform", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          transform: navState ? "translateX(0)" : "translateX(-100%)",
          overflowY: "auto",
          overflowX: "hidden",
        })}>
        <NavContent
          pathParams={pathParams}
          pathname={pathname}
          communitySlug={communitySlug}
          isSidebarOpen={navState}
        />
      </Box>
    </Suspense>
  </Box>
);

export default SlidingNav;
