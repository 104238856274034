import { useEffect } from "react";
import { useTrackEvent } from "@/services";

export const useArrowKeyNavigation = (): void => {
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        trackEvent({
          action: "navigate-guide",
          other: {
            method: "arrow-key",
            direction: "previous",
          },
        });
        document.getElementById("prevLink")?.click();
      } else if (event.key === "ArrowRight") {
        trackEvent({
          action: "navigate-guide",
          other: {
            method: "arrow-key",
            direction: "next",
          },
        });
        document.getElementById("nextLink")?.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};
