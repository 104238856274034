import { useMemo } from "react";
import { Unit } from "@/store/api/graphql/generated/types";
import { orderUnits } from "@/utils/Library/helpers";
import { toMilSecs } from "@/utils/Library/dates";
import { sortByKeyTopicTitle } from "@/utils/Library/contentTypes";

const sortUnitResources = (units: Unit[], getAll = false) => {
  if (!units) return [];
  return units.map((unit) => {
    const resources = [...(unit.resources || [])]
      .sort((a, b) => (toMilSecs(b.date) - toMilSecs(a.date) > 0 ? -1 : 1))
      .sort(sortByKeyTopicTitle);

    return { ...unit, resources: getAll ? resources : resources.slice(0, 6) };
  });
};
export function useSortUnits(units: Unit[], getAll = false): { courseUnits: Unit[]; addlResources: Unit[] } {
  const sortedUnits = sortUnitResources(units, getAll);
  return useMemo(() => {
    let courseUnitsArr: Unit[] = [];
    let addlResourcesArr: Unit[] = [];
    if (!units) return { courseUnits: [], addlResources: [] };

    sortedUnits.forEach((unit) => {
      if (unit.slug?.includes("unit-")) courseUnitsArr.push(unit);
      else addlResourcesArr.push(unit);
    });

    courseUnitsArr = orderUnits(courseUnitsArr);
    addlResourcesArr = orderUnits(addlResourcesArr).filter(
      (unit) => unit.hasResources ?? (unit.resources?.length ?? 0) > 0
    );

    return { courseUnits: courseUnitsArr, addlResources: addlResourcesArr };
  }, [units]);
}
