import React, { useState, useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import dynamic from "next/dynamic";
import SubjectsAutocompleteInput from "@/components/Library/NewCustomEditor/SubjectsAutocompleteInput";
import { Form, Formik } from "formik";
import { Typography } from "@mui/material";
import { Button } from "@/components-modern/atoms/button/Button";
import { ISubjectAutocompleteSubject } from "@/types";
import { getSearchableSubjects } from "@/components-modern/molecules/subject-search/SubjectSearch";
import { useModernDispatch } from "@/store/hooks";
import useUpdateQueryParams from "@/hooks/useUpdateQueryParams";
import { useTrackEvent } from "@/services";
import {
  CLICK_SAVE_SUBJECT_TO_PROFILE,
  CLICK_SAVE_SUBJECTS_IN_MODAL,
} from "@/utils/analyticsConstants/genericAnalyticsInfo";
import { useAuthModals } from "../../../hooks/modals/useAuthModals";
import GenericDialog from "../generic-dialog/GenericDialog";

const ClassSelectModal = ({
  subjects,
  setSubjects,
  allSubjects = [],
  isLoading,
  onSubmit,
  open,
  handleClose,
}: {
  subjects: ISubjectAutocompleteSubject[];
  setSubjects: (newSubjects: ISubjectAutocompleteSubject[]) => void;
  onSubmit: (values: { subjects: ISubjectAutocompleteSubject[] }, skipToLogin?: boolean) => void;
  allSubjects?: ISubjectAutocompleteSubject[];
  isLoading: boolean;
  open: boolean;
  handleClose: () => void;
}): JSX.Element => {
  return (
    <GenericDialog
      showCloseButton
      open={open}
      onCancel={handleClose}
      id="mobile-guides-dialog"
      dialogContentStyles={{
        justifyContent: "flex-start",
        flexDirection: "column",
      }}>
      <Typography variant="h4">hey what classes are you taking this year?</Typography>
      <Formik initialValues={{ subjects }} onSubmit={(values) => onSubmit(values, false)}>
        <Form>
          <SubjectsAutocompleteInput
            isLoading={isLoading}
            newSubjects={subjects}
            setNewSubjects={setSubjects}
            initialSubjects={allSubjects}
            placeholder={isLoading ? "Loading..." : undefined}
          />
          <Button onClick={() => onSubmit({ subjects })} variant="secondary">
            save subjects
          </Button>
        </Form>
      </Formik>
      <Button variant="text" sx={{ mt: 2 }} onClick={() => onSubmit({ subjects }, true)}>
        I&apos;ve already selected my classes, log me in
      </Button>
    </GenericDialog>
  );
};

const WrappedClassSelect = ({ subjectSlug }: { subjectSlug: string }): JSX.Element => {
  "use client";

  const dispatch = useModernDispatch();
  const { showExpandedSignupModal, showExpandedLoginModal } = useAuthModals();
  const pathname = usePathname() ?? "";
  const searchParams = useSearchParams();
  /* eslint-disable no-param-reassign */
  const queryParams = searchParams
    ? Array.from(searchParams?.entries() ?? []).reduce((a, [key, val]) => {
        if (a[key]) {
          if (Array.isArray(a[key])) a[key].push(val);
          else a[key] = [a[key], val];
        } else a[key] = val;
        return a;
      }, {})
    : {};
  /* eslint-enable no-param-reassign */
  const { updateQueryParams } = useUpdateQueryParams(pathname, queryParams);
  const { trackEvent } = useTrackEvent();

  const [allSubjects, setAllSubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (values, skipToLogin) => {
    trackEvent({
      ...CLICK_SAVE_SUBJECTS_IN_MODAL,
      other: {
        subjectIds: subjects?.map((subject) => subject.id) || [],
        isLoggedIn: false,
        autoOpen: false,
        numSubjectsSaved: subjects?.length || 0,
      },
    });
    updateQueryParams({
      newParams: [
        { paramName: "saving-subjects", paramValue: "true" },
        {
          paramName: "subjects",
          paramValue: subjects.map((subject) => subject.id),
        },
      ],
    });
    if (skipToLogin) {
      showExpandedLoginModal({ type: "cheatsheet" });
      handleClose();
      return;
    }
    // add url param to save subjects
    showExpandedSignupModal({ type: "cheatsheet" });
    handleClose();
  };

  // experiment on guide pages to automatically prompt user to add subjects after 1 min
  const localStorageKey = "subSelect";

  useEffect(() => {
    if (!open) return;
    setIsLoading(true);
    getSearchableSubjects({ dispatch })
      .then((res) => {
        const searchableSubjects = res.data.getSearchableSubjects;
        const currentSubject = searchableSubjects.find((subject) => subject.slug === subjectSlug);
        const currentAutoCompleteSubject = {
          ...currentSubject,
          label: `${currentSubject.emoji} ${currentSubject.name} `,
        };
        if (currentSubject) {
          setSubjects([currentAutoCompleteSubject]);
        }
        setAllSubjects(res.data.getSearchableSubjects);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [open]);

  return (
    <>
      {/* Note: this is similar in appearance but different in functionality from the component SaveSubjectButton */}
      <Button
        variant="primary"
        onClick={() => {
          trackEvent({
            ...CLICK_SAVE_SUBJECT_TO_PROFILE,
            other: {
              subjectId: subjects[0]?.id,
              isLoggedIn: false,
            },
          });
          setOpen(true);
          // mark this modal as seen for today
          localStorage.setItem(localStorageKey, Date.now().toString());
        }}>
        Save subject
      </Button>
      <ClassSelectModal
        subjects={subjects}
        allSubjects={allSubjects}
        isLoading={isLoading}
        setSubjects={setSubjects}
        onSubmit={onSubmit}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default dynamic(() => Promise.resolve(WrappedClassSelect), { ssr: false });
