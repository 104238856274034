import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useFormikContext, useField } from "formik";
import useProfile from "@/utils/useProfile";
import { ProfileLevel } from "@hours/utilities";
import { Select } from "@/components-modern/atoms/select/Select";

const QUESTION = "What level are you at?";
const LEVEL_OPTIONS = Object.values(ProfileLevel);

type ThemeWithError = Theme & {
  palette: {
    text: {
      error: string;
    };
  };
};

// TODO: update this component to use modern components and styles
const LevelSelect = (): JSX.Element => {
  const { profile } = useProfile();

  const formikProps = useFormikContext();
  const [level, setLevel] = useState(profile?.level);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [levelField, levelMeta] = useField("level");
  const hasError = levelMeta.error && levelMeta.touched;

  useEffect(() => {
    if (profile?.level) {
      formikProps.setFieldValue("level", profile?.level || null);
    }
  }, [profile?.level]);

  const handleChange = (e) => {
    setLevel(e.target.value);
    formikProps.setFieldValue("level", e.target.value);
  };

  return (
    <div>
      <Typography variant="body1" mb={1}>
        <strong>{QUESTION}</strong>
      </Typography>
      <Select
        placeholder="Select a level"
        value={level}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: hasError ? "red" : "",
          },
        }}
        onChange={handleChange}
        options={LEVEL_OPTIONS.map((levelOption) => {
          return {
            value: levelOption,
            label: levelOption,
            key: levelOption,
          };
        })}
        fullWidth
      />

      {hasError && (
        <Typography
          sx={(theme: ThemeWithError) => ({
            pl: 2,
            mt: 1.5,
            color: theme.palette.error.main,
            fontSize: ".75rem",
          })}>
          You must select a level.
        </Typography>
      )}
    </div>
  );
};

export default LevelSelect;
