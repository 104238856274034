import { Popover as MuiPopover, PopoverProps as MuiPopoverProps } from "@mui/material";
import React from "react";

export type PopoverProps = MuiPopoverProps & {
  paperSx?: React.CSSProperties;
};

const Popover = ({
  open,
  id,
  onClose,
  anchorEl,
  sx = {},
  paperSx = {},
  children,
  anchorOrigin = { vertical: "bottom", horizontal: "right" },
  transformOrigin = { vertical: "top", horizontal: "right" },
  ...props
}: PopoverProps): JSX.Element => {
  return (
    <MuiPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={{
        marginTop: "5px",
        "& .MuiPopover-paper": {
          minHeight: "300px",
          width: "300px",
          padding: 2,
          border: "2px solid black",
          ...paperSx,
        },
        ...sx,
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...props}>
      {children}
    </MuiPopover>
  );
};

export default Popover;
