import { Box, Typography } from "@mui/material";

const Copyright = (): JSX.Element => {
  return (
    <Box>
      <Typography variant="subtitle2">
        &copy; {new Date().getFullYear()} Fiveable Inc. All rights reserved.
      </Typography>
      <Typography variant="subtitle2">
        AP&reg; and SAT&reg; are trademarks registered by the College Board, which is not affiliated with, and
        does not endorse this website.
      </Typography>
    </Box>
  );
};

export default Copyright;
