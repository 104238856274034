import React from "react";
import { Button } from "@/components-modern/atoms/button/Button";
import { SxProps } from "@mui/material";
import { useTrackEvent } from "@/services";

interface ContinueWithoutSubscribingProps {
  onClick: () => void;
  sx?: SxProps;
  trackingEvent?: {
    category: string;
    action: string;
    other?: Record<string, unknown>;
  };
}

const ContinueWithoutSubscribing: React.FC<ContinueWithoutSubscribingProps> = ({
  onClick,
  sx = {},
  trackingEvent,
}) => {
  const { trackEvent } = useTrackEvent();

  const handleClick = () => {
    if (trackingEvent) {
      trackEvent(trackingEvent);
    }
    onClick();
  };

  return (
    <Button variant="textPrimary" sx={{ color: "text.primary", ...sx }} onClick={handleClick}>
      continue without subscribing
    </Button>
  );
};

export default ContinueWithoutSubscribing;
