import { useMemo, useRef, useEffect } from "react";
import { CramPass, hasCramMode, getCramPass } from "@hours/utilities";
import useProfile from "@/utils/useProfile";
import { CRAM_SEASON_PASS, CRAM_DAY_PASS } from "@hours/constants";
import { useProfileService } from "@/services";
import { useAuthentication } from "@/utils/useAuthentication";

export const useHasCramMode = (): boolean => {
  const { isSuperAdmin, isContentAuthor, isContentAdmin, isStudentAdmin, isCramHost } = useAuthentication();
  const hasExtraPrivileges =
    isSuperAdmin || isContentAuthor || isContentAdmin || isStudentAdmin || isCramHost;

  const { isProfileLoading, profile } = useProfile();
  return useMemo(() => {
    if (isProfileLoading || hasExtraPrivileges) return true;
    return hasCramMode(profile);
  }, [isProfileLoading, profile, hasExtraPrivileges]);
};

export const useCramRole = (): CramPass | null => {
  const { isProfileLoading, profile } = useProfile();
  return useMemo(() => (isProfileLoading ? null : getCramPass(profile)), [isProfileLoading, profile]);
};

export const useHasCancelledCramMode = (): boolean => {
  const { isProfileLoading, profile } = useProfile();
  const cramRole = profile?.roles?.find((role) => role.role === CRAM_SEASON_PASS);
  const hasCancelled = cramRole?.status === "cancelled";
  return useMemo(() => (isProfileLoading ? false : hasCancelled), [isProfileLoading, profile]);
};

// TODO: doesn't handle case where a user has an expired pass of type A but a valid pass of type B.
export const useGetCramExpiry = (): string | Date => {
  const { isProfileLoading, profile } = useProfile();
  return useMemo(() => {
    const roles = profile?.roles;
    const cramRole = roles?.find((role) => role.role === CRAM_SEASON_PASS || role.role === CRAM_DAY_PASS);
    return cramRole?.expiry;
  }, [isProfileLoading, profile]);
};

// poll database for cram mode status since user can get redirected here before the webhook has updated their profile
export const usePollForCramModeStatus = ({ hasCramModeStatus }: { hasCramModeStatus: boolean }): void => {
  const { getProfile } = useProfileService();
  const intervalRef = useRef(0);
  useEffect(() => {
    let interval = null;
    if (!hasCramModeStatus) {
      interval = setInterval(async () => {
        await getProfile();
        if (hasCramModeStatus) {
          clearInterval(interval);
        } else {
          intervalRef.current += 1;
          if (intervalRef.current > 10) {
            clearInterval(interval);
          }
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [hasCramModeStatus]);
};
