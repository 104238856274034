import React from "react";
import { Stack, Typography } from "@mui/material";
import Link from "@/components-modern/atoms/link/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

interface NextBoxProps {
  nextLink: string;
  nextIsEnabled: boolean;
  nextLabel: string;
  nextLinkProps: {
    scroll?: boolean;
    replace?: boolean;
    [key: string]: unknown;
  };
  trackClick: (args: { eventLabel: string; eventProps?: Record<string, unknown> }) => void;
}

const NextBox: React.FC<NextBoxProps> = ({
  nextLink,
  nextIsEnabled,
  nextLabel,
  nextLinkProps,
  trackClick,
}) => {
  return (
    <Stack
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      width="15%"
      sx={{
        overflow: "visible",
        flexGrow: 1,
        flexShrink: 0,
      }}>
      <Link
        href={nextLink || ""}
        variant="primary"
        id="nextLink"
        pointerEvents={nextIsEnabled && nextLink ? "auto" : "none"}
        linkStyles={{
          visibility: nextIsEnabled && nextLink ? "visible" : "hidden",
          maxWidth: "none",
          display: "flex",
        }}
        onClick={() =>
          trackClick({
            eventLabel: nextLabel,
          })
        }
        {...nextLinkProps}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0, md: 1 }}
          sx={{
            minWidth: 0,
            maxWidth: "100%",
          }}>
          <Typography
            variant="subtitle2"
            sx={{
              display: { xs: "none", md: "block" },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexShrink: 1,
              minWidth: 0,
            }}>
            {nextLabel}
          </Typography>
          <Stack
            width=".5rem"
            ml={1}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}>
            <FontAwesomeIcon icon={faChevronRight} style={{ width: "100%" }} />
          </Stack>
        </Stack>
      </Link>
    </Stack>
  );
};

export default NextBox;
