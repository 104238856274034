import React from "react";
import Head from "next/head";
import { BASE_URL } from "@/utils/Library/constants/urls";

const defaultTitle =
  "Thousands of free videos, practice FRQs/DBQs, live trivia battles, and more AP test prep resources. Start studying today to get that 5!";
const defaultDescription = "Free Support in All AP Subjects & Live Stream Reviews | Fiveable";
const defaultImgPath = "img/ogimages/ogimage-3.png";

export interface MetaProps {
  title?: string;
  description?: string;
  imgPath?: string;
  urlPath?: string; // canonical URL path, not including BASE_URL
  schemaData?: string[]; // all relevant JSON-LD schema datas
  shouldNoIndex?: boolean; // dont show this page in google
}

const Meta = ({
  description = defaultDescription,
  imgPath = defaultImgPath,
  title = defaultTitle,
  urlPath,
  shouldNoIndex,
}: MetaProps): JSX.Element => {
  const canonicalUrl = urlPath ? `${BASE_URL}${urlPath}` : null;

  // we don't want to index library.dev.fiveable.me pages
  const isLibraryDev = process.env.NEXT_PUBLIC_LIBRARY_BASE_URL?.includes("dev");

  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      {/* formats for google & og:
        google: https://developers.google.com/search/docs/appearance/snippet#meta-descriptions
        OG tags: https://ahrefs.com/blog/open-graph-meta-tags/
      */}
      <meta name="description" content={description} /> {/* this for google */}
      <meta property="og:description" content={description} /> {/* this for erryone else */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <meta property="og:image" content={`${BASE_URL}/${imgPath}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={`${BASE_URL}/${imgPath}`} />
      <meta name="robots" content={shouldNoIndex || isLibraryDev ? "noindex,nofollow" : "index,follow"} />
    </Head>
  );
};

export default Meta;
