"use client";

import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import useBottomNav from "@/hooks/Library/useBottomNav";
import { useArrowKeyNavigation } from "@/hooks/Library/useArrowKeyNavigation";
import { useTrackEvent } from "@/services";
import { navbarAnalyticsInfo } from "@/utils/analyticsConstants";

import { ComponentType, PageType, useSimpleCtaExperiment } from "@/hooks/useSimpleCtaExperiment";
import PreviousBox from "./PreviousBox";
import MiddleBox from "./MiddleBox";
import NextBox from "./NextBox";

// this preserves existing functionality to scroll to the pep quiz sometimes
const getLinkProps = (href) => {
  const isSectionLink = href?.indexOf("#") === -1;
  const scroll = !isSectionLink;
  const replace = !!isSectionLink;
  return { scroll, replace };
};

interface BottomNavDesktopProps {
  pageData?: {
    practiceMore?: boolean;
    practiceMoreText?: string;
    practiceUrl?: string | null;
    hasKeyTerms?: boolean;
    practiceEventProps?: {
      numOfQuestions: number;
    };
    [key: string]: unknown;
  };
  hasPracticeQuestions?: boolean;
  pathParams?: Record<string, string | string[]>;
  pathname?: string;
}

// eslint-disable-next-line max-lines-per-function
const BottomNavDesktop = ({
  pageData,
  hasPracticeQuestions,
  pathParams = {},
  pathname = "",
}: BottomNavDesktopProps): JSX.Element => {
  const { trackEvent } = useTrackEvent();
  const [open, setOpen] = useState(false);
  const { nextPrevLinkInfo, linkGroupLinksDesktop, linkGroupLinksMobile } = useBottomNav({
    pageData,
    hasPracticeQuestions,
    pathParams,
    pathname,
  });

  useArrowKeyNavigation();

  const hideOtherThanNext = !useSimpleCtaExperiment(ComponentType.OTHER, PageType.GENERAL);

  useArrowKeyNavigation();
  const prevLinkProps = getLinkProps(nextPrevLinkInfo.prev.href);
  const nextLinkProps = getLinkProps(nextPrevLinkInfo.next.href);
  const nextLink = nextPrevLinkInfo.next.href;
  const prevLink = nextPrevLinkInfo.prev.href;
  const nextIsEnabled = nextPrevLinkInfo.next.isEnabled;
  const prevIsEnabled = nextPrevLinkInfo.prev.isEnabled;

  // don't show bottom nav on homepage
  const hasNoLinkGroupLinks = !linkGroupLinksDesktop?.length || !linkGroupLinksMobile?.length;

  const trackClick = ({ eventLabel, eventProps = {} }) => {
    trackEvent({
      ...navbarAnalyticsInfo.CLICK_BOTTOM_NAV_LINK,
      label: eventLabel,
      other: eventProps,
    });
  };

  // close modal when url
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const noLinks = !nextPrevLinkInfo.next.href && !nextPrevLinkInfo.prev.href && hasNoLinkGroupLinks;
  if (pathname === "/" || noLinks) return null;

  const nextLabel = nextPrevLinkInfo.next.label;
  const prevLabel = "Back";

  return (
    <Box
      data-testid="BottomNav"
      sx={(theme) => ({
        background: theme.palette.background.paper,
        borderTop: `2px solid ${theme.palette.divider}`,
        position: "fixed",
        bottom: 0,
        right: 0,
        width: "100%",
        zIndex: 1302, // move this above SlidingNav ... and practice paywall in sidebar
        paddingY: 1,
        transition: theme.transitions.create(["width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      })}>
      <Box
        sx={{
          margin: "0 auto",
          paddingX: { xs: "15px", sm: "20px", md: "40px" },
          width: "100%",
          display: "flex",
          gap: {
            xs: "8px",
            md: "0",
          },
          justifyContent: nextIsEnabled && prevIsEnabled ? "space-between" : "center",
          alignItems: "center",
        }}>
        {!hideOtherThanNext && (
          <PreviousBox
            prevLink={prevLink}
            prevIsEnabled={prevIsEnabled}
            prevLabel={prevLabel}
            prevLinkProps={prevLinkProps}
            trackClick={trackClick}
          />
        )}
        {!hideOtherThanNext && (
          <MiddleBox
            linkGroupLinksMobile={linkGroupLinksMobile}
            linkGroupLinksDesktop={linkGroupLinksDesktop}
            hasPracticeQuestions={hasPracticeQuestions}
            pageData={pageData}
            pathname={pathname}
            pathParams={pathParams}
            trackClick={trackClick}
            open={open}
            setOpen={setOpen}
          />
        )}
        <NextBox
          nextLink={nextLink}
          nextIsEnabled={nextIsEnabled}
          nextLabel={nextLabel}
          nextLinkProps={nextLinkProps}
          trackClick={trackClick}
        />
      </Box>
    </Box>
  );
};

export default BottomNavDesktop;
