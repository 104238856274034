import {
  SUBJECTS_AND_UNITS_AND_TOPICS_WITH_PRACTICE_QUESTIONS,
  SUBJECTS_AND_UNITS_AND_TOPICS_WITH_AP_PRACTICE_QUESTIONS,
} from "@/utils/constants";
import { useTrackEvent } from "@/services";
import { generateTabUrl } from "@/utils/readingPracticeNavUtils";

export const NUM_PRACTICE_QUESTIONS = 5;
const ADDITIONAL_RESOURCES_REGEX =
  /(blog|slides|watch|study-tools|faqs|exam-skills|previous-exam-prep|crams-2021|crams-2022)/;

export const isSubjectWithPracticeQuestions = ({
  subjectSlug,
  unitPageSlug,
  questionType,
}: {
  subjectSlug?: string;
  unitPageSlug?: string;
  questionType?: string;
}): boolean => {
  const JSONToSearch =
    questionType === "ap"
      ? SUBJECTS_AND_UNITS_AND_TOPICS_WITH_AP_PRACTICE_QUESTIONS
      : SUBJECTS_AND_UNITS_AND_TOPICS_WITH_PRACTICE_QUESTIONS;

  const subjectWithQuestions = JSONToSearch?.find((sub) => sub?.slug === subjectSlug);

  // if unit matches additional resources, practice toggle will redirect to generalized page as was done previously
  const unitWithQuestions =
    subjectWithQuestions?.units?.find((unit) => unit?.slug === unitPageSlug) ||
    unitPageSlug?.match(ADDITIONAL_RESOURCES_REGEX);

  // Sometimes a subject may have practice questions but not all of its units do, this allows for a more fine grain check
  const hasPracticeRegQuestions = subjectSlug && unitPageSlug ? unitWithQuestions : subjectWithQuestions;

  return Boolean(hasPracticeRegQuestions);
};

interface IUseReadingPracticeUrlProps {
  pageData: { content?: { topics?: Array<{ id: string }>; id?: string; slug?: string } };
  pathParams: Record<string, string | string[]> | { [key: string]: string | string[] };
  pathname: string | null;
  push: (route: string) => void;
}

export const useGetReadingPracticeUrl = ({
  pageData,
  pathParams,
  pathname,
  push,
}: IUseReadingPracticeUrlProps): {
  handleTabChange: (event: React.ChangeEvent<unknown>, newValue: number) => void;
  initialValue: number;
} => {
  // const router = useRouter();

  const { trackEvent } = useTrackEvent();
  const isPracticePage = pathname?.includes("/practice");
  const isReadingPage = pathname?.includes("/study-guide");
  const isAPStylePracticePage = pathname?.includes("/ap-style-practice");

  const topicId = pageData?.content?.topics?.[0]?.id;
  const contentId = pageData?.content?.id;
  const contentSlug = pageData?.content?.slug;

  let practiceUrl = pathname;
  let readingUrl = pathname;
  let apStyleUrl = pathname;
  let initialValue = 0;

  if (!isPracticePage) {
    practiceUrl = generateTabUrl({
      pathParams,
      pathname,
      contentSlug,
      contentId,
      topicId,
      generatedUrlType: "practice",
    });
  } else {
    initialValue = 1;
  }

  if (!isReadingPage) {
    readingUrl = generateTabUrl({
      pathParams,
      pathname,
      contentSlug,
      contentId,
      topicId,
      generatedUrlType: "reading",
    });
  } else {
    initialValue = 0;
  }
  if (!isAPStylePracticePage) {
    apStyleUrl = generateTabUrl({
      pathParams,
      pathname,
      contentSlug,
      contentId,
      topicId,
      generatedUrlType: "ap-style-practice",
    });
  } else {
    initialValue = 2;
  }

  const tabOrder = {
    0: readingUrl,
    1: practiceUrl,
    2: apStyleUrl,
  };

  const handleTabChange = (_, newValue) => {
    const url = tabOrder[newValue];
    trackEvent({ action: "primary-navigation-change", other: { to: url } });
    push(url);
  };

  return { handleTabChange, initialValue };
};

export const useGetTabData = ({
  shouldShowAPTab = false,
}: {
  shouldShowAPTab: boolean;
}): { id: string; label: string; isNew?: boolean }[] => {
  // this is a temporary solution to our naming problem differentiating different types of practice
  const practiceLabel = shouldShowAPTab ? "Quizzes" : "Practice";

  const tabData: { id: string; label: string; isNew?: boolean }[] = [
    { id: "reading", label: "Reading" },
    { id: "practice", label: practiceLabel },
  ];

  if (shouldShowAPTab) tabData.push({ id: "ap-style", label: "AP Review", isNew: true });
  return tabData;
};
