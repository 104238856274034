import { getLinkForContent } from "@/utils/Library/contentTypes";
import { slug as createSlug } from "@hours/utilities";
import { useModernSelector } from "@/store/hooks";
import { selectActiveSubject, selectActiveUnit, selectUnits } from "@/store/slices/TableOfContentsSlice";
import { useSortUnits } from "@/utils/useSortUnits";
import { Unit, Subject } from "@/store/api/graphql/generated/types";
import { unitSlugRegex } from "@/utils/Library/unitSlugRegex";
import { getRouterSlugString } from "@/utils/Library/helpers";
import { Logger } from "@/utils/logger";

const LOGID = "fiveable:frontend:hooks/Library/useContentHeadline.ts";
const log = Logger(LOGID);
interface ListItem {
  id?: string;
  name: string;
  emoji: string;
  url: string;
  formattedTopics?: ListItem[];
}
interface IuseContentHeadlineReturn {
  subject?: Subject;
  unitObj?: Unit;
  topicUrl: string;
  unitUrl: string;
  subjectUrl: string;
  formattedSubjectUnits: ListItem[];
  unitsAndTopics: ListItem[];
  unitsAndTopicsObj: ListItem[];
  unitSlug: string[] | string;
}

// eslint-disable-next-line max-lines-per-function
const useContentHeadline = (
  pathParams:
    | Record<string, string | string[] | undefined>
    | { [key: string]: string | string[] | undefined },
  pathname: string
): IuseContentHeadlineReturn => {
  const {
    slug: practiceSlugArr,
    communitySlug,
    subjectSlug: routerSubjectSlug,
    unitSlug: routerUnitSlug,
    contentSlug: routerContentSlug,
  } = pathParams ?? {};

  const routerCombinedSubjectSlug = communitySlug || routerSubjectSlug || practiceSlugArr?.[0];

  // resolves the string | string[] issue
  const subjectSlug = getRouterSlugString(routerCombinedSubjectSlug);
  const unitSlug = getRouterSlugString(routerUnitSlug);
  const contentSlug = getRouterSlugString(routerContentSlug);

  const subjectUrl = `/${subjectSlug}`;
  const subject = useModernSelector(selectActiveSubject);
  const unitList = useModernSelector(selectUnits);
  const activeUnit = useModernSelector(selectActiveUnit);
  const content = activeUnit?.resources?.find(
    (r) => r.slug.toLowerCase() === ((contentSlug as string) || "").toLowerCase()
  );
  const unitSlugString = Array.isArray(unitSlug) ? unitSlug[0] : unitSlug;
  const unitObj = unitList.find((u) => u.slug === unitSlugRegex(unitSlugString));

  // skip getting the link if content/topic is not yet selectable (user has chosen only subject and not a unit yet)
  const topicUrl =
    content?.id &&
    getLinkForContent({
      item: content,
      subjectSlug: subject?.slug ?? "",
      unitSlug: unitObj?.slug,
    });

  const unitUrl = unitObj?.slug && `/${subject?.slug || createSlug(subject?.name)}/${unitObj?.slug}`;

  // the true here is to get all the resources
  // right now on some pages we only get a few to display
  // but for ToC we want them all
  const { courseUnits, addlResources } = useSortUnits(unitList, true);
  const formattedSubjectUnits = [...courseUnits, ...addlResources]
    .filter((u) => u.active)
    .map((u) => {
      return {
        name: u.name,
        emoji: u.emoji,
        url: `/${subjectSlug?.slug || createSlug(subject?.name)}/${u?.slug || createSlug(u?.name)}`,
      };
    });

  const unitsAndTopics = [];
  const unitsAndTopicsObj = [];

  [...courseUnits, ...addlResources]?.forEach((course) => {
    const formattedTopics = course.resources.map((resource) => {
      return {
        name: resource.title,
        emoji: "",
        url: getLinkForContent({
          item: resource,
          subjectSlug: subject?.slug,
          unitSlug: course?.slug,
        }),
      };
    });

    const unitWithTopics = {
      ...course,
      formattedTopics,
    };

    unitsAndTopicsObj.push(unitWithTopics);
    unitsAndTopics.push(unitWithTopics, ...formattedTopics);
  });

  // useEffect(() => {
  if (!subject && !pathname.includes("my-stuff")) {
    log.warn(`missing subject on page: ${pathname}`);
  }
  // }, [pathname]);

  return {
    subject,
    unitObj,
    topicUrl,
    unitUrl,
    subjectUrl,
    formattedSubjectUnits,
    unitsAndTopics,
    unitsAndTopicsObj: unitsAndTopicsObj.filter((unit) => unit?.resources?.length > 0),
    unitSlug,
  };
};

export default useContentHeadline;
