export const BASE_URL = "https://library.fiveable.me";
export const COMMUNITY_BASE_URL = "https://community.fiveable.me/";
export const COMMUNITY_TOPIC_BASE_URL = `${COMMUNITY_BASE_URL}t/-/`;
export const CRAM_PASS_WAITLIST_URL = "https://thinkfiveable.typeform.com/to/mesxez";

export const BULK_DASHBOARD_PATH = "/program-distribution-dashboard";

export const SUBJECT_STUDY_GUIDE_URLS = {
  "ap-biology": "https://fiveable.me/ap-bio/ap-biology-study-guide-2020/",
  "ap-calculus": "https://fiveable.me/ap-calculus-ab-bc/ap-calc-study-guide-2020/",
  "ap-chemistry": "https://fiveable.me/ap-chemistry/ap-chemistry-study-guide/",
  "ap-english-language": "https://fiveable.me/ap-lang/ap-lang-study-guide-2020/",
  "ap-english-literature": "https://fiveable.me/ap-lit/ap-lit-study-guide-2020/",
  "ap-environmental-science": "https://fiveable.me/apes/ap-environmental-science-study-guide-2020/",
  "ap-european-history": "https://fiveable.me/ap-euro/ap-euro-study-guide-2020/",
  "ap-human-geography": "https://fiveable.me/ap-hug/ap-human-geography-study-guide-2020/",
  "ap-macroeconomics": "https://fiveable.me/ap-macroeconomics/ap-macro-study-guide-2020/",
  "ap-microeconomics": "https://fiveable.me/ap-microeconomics/ap-micro-study-guide-2020/",
  "ap-physics-1": "https://fiveable.me/ap-physics/ap-physics-study-guide-2020/",
  "ap-psychology": "https://fiveable.me/psych/ap-psych-study-guide-2020/",
  "ap-statistics": "https://fiveable.me/ap-stats/ap-stats-study-guide-2020/",
  "ap-us-government": "https://fiveable.me/ap-gov/ap-gov-study-guide-2020/",
  "ap-us-history": "https://fiveable.me/apush/apush-study-guide-2020/",
  "ap-world-history": "https://fiveable.me/ap-world/ap-world-study-guide-2020/",
};
