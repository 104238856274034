import useABTest, { ABVariant, Experiment } from "./useABTest";

export enum PageType {
  KEY_TERMS = "keyTerms",
  GUIDES = "guides",
  UNIT = "unit",
  BROWSE = "browse",
  SUBJECT = "subject",
  GENERAL = "general",
}

export enum ComponentType {
  TOP_NAV_CTA = "topNavCta",
  BOTTOM_NAV = "bottomNav",
  LEFT_NAV = "leftNav",
  RIGHT_NAV = "rightNav",
  NEXT_BUTTON = "nextButton",
  OTHER = "other",
  SEARCH = "search",
}

interface SimpleCtaExperimentPayload {
  topNavCta: boolean;
  bottomNav: boolean;
  leftNav: boolean;
  rightNav: boolean;
  nextButton: boolean;
  other: boolean;
  search: boolean;
}

// These represent the green/red line drawings here https://www.figma.com/board/51gOoCtWg7bW8H0cHGyuRu/paths-%2F-copy?node-id=19-89&t=tl05VaWFl3jtZwKK-4
const experimentPayloads = {
  control: {
    general: {
      topNavCta: true,
      bottomNav: true,
      leftNav: true,
      rightNav: true,
      nextButton: true,
      other: true,
      search: true,
    },
    keyTerms: {
      topNavCta: true,
      bottomNav: true,
      leftNav: true,
      rightNav: true,
      nextButton: true,
      other: true,
      search: true,
    },
    unit: {
      topNavCta: true,
      bottomNav: true,
      leftNav: true,
      rightNav: true,
      nextButton: true,
      other: true,
      search: true,
    },
    subject: {
      topNavCta: true,
      bottomNav: true,
      leftNav: true,
      rightNav: true,
      nextButton: true,
      other: true,
      search: true,
    },
    guides: {
      topNavCta: true,
      bottomNav: true,
      leftNav: true,
      rightNav: true,
      nextButton: true,
      other: true,
      search: true,
    },
    browse: {
      topNavCta: true,
      bottomNav: true,
      leftNav: true,
      rightNav: true,
      nextButton: true,
      other: true,
      search: true,
    },
  },
  variant1: {
    general: {
      topNavCta: false,
      bottomNav: false,
      leftNav: true,
      rightNav: false,
      nextButton: false,
      other: false,
      search: true,
    },
    keyTerms: {
      topNavCta: false,
      bottomNav: false,
      leftNav: true,
      rightNav: false,
      nextButton: false,
      other: false,
      search: true,
    },
    unit: {
      topNavCta: false,
      bottomNav: false,
      leftNav: true,
      rightNav: false,
      nextButton: false,
      other: false,
      search: true,
    },
    subject: {
      topNavCta: false,
      bottomNav: false,
      leftNav: true,
      rightNav: false,
      nextButton: false,
      other: false,
      search: true,
    },
    guides: {
      topNavCta: false,
      bottomNav: false,
      leftNav: true,
      rightNav: false,
      nextButton: false,
      other: false,
      search: true,
    },
    browse: {
      topNavCta: false,
      bottomNav: false,
      leftNav: true,
      rightNav: false,
      nextButton: false,
      other: false,
      search: true,
    },
  },
  variant2: {
    general: {
      topNavCta: false,
      bottomNav: true,
      leftNav: false,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    keyTerms: {
      topNavCta: false,
      bottomNav: false,
      leftNav: false,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    unit: {
      topNavCta: false,
      bottomNav: true,
      leftNav: false,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    subject: {
      topNavCta: false,
      bottomNav: true,
      leftNav: false,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    guides: {
      topNavCta: false,
      bottomNav: true,
      leftNav: false,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    browse: {
      topNavCta: false,
      bottomNav: true,
      leftNav: false,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
  },
  variant3: {
    general: {
      topNavCta: false,
      bottomNav: false,
      leftNav: true,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    keyTerms: {
      topNavCta: false,
      bottomNav: false,
      leftNav: true,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    unit: {
      topNavCta: false,
      bottomNav: true,
      leftNav: true,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    subject: {
      topNavCta: false,
      bottomNav: true,
      leftNav: true,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    guides: {
      topNavCta: false,
      bottomNav: true,
      leftNav: true,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
    browse: {
      topNavCta: false,
      bottomNav: true,
      leftNav: true,
      rightNav: false,
      nextButton: true,
      other: false,
      search: true,
    },
  },
};

export const useSimpleCtaExperiment = (componentType: ComponentType, pageType: PageType): boolean => {
  const experimentInfo: Experiment<SimpleCtaExperimentPayload> = useABTest({
    experimentKey: "simple-cta",
    fallbackVariant: {
      value: ABVariant.CONTROL,
      payload: experimentPayloads[ABVariant.CONTROL][PageType.KEY_TERMS],
    },
  });

  const variantValue = experimentInfo.value;
  const variantPayload = experimentPayloads[variantValue];
  if (!variantPayload) {
    return true;
  }

  const pageTypePayload = variantPayload[pageType];
  if (!pageTypePayload) {
    return true;
  }

  const componentPayload = pageTypePayload[componentType];
  if (!componentType) {
    return true;
  }

  return componentPayload;
};
