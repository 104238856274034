import React from "react";
import { Typography, Box, CircularProgress, SxProps } from "@mui/material";

const LoadingSpinnerText = ({
  text = "Loading...",
  containerStyles = {},
  spinnerSize = 15,
}: {
  text?: string;
  containerStyles?: SxProps;
  spinnerSize?: number;
}): JSX.Element => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", ...containerStyles }}>
      <CircularProgress size={spinnerSize} sx={{ mr: 2 }} />
      <Typography>{text}</Typography>
    </Box>
  );
};

export default LoadingSpinnerText;
