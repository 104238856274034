import React, { useState } from "react";
import dynamic from "next/dynamic";
import { IconButton, Drawer, Box } from "@mui/material";
import HamburgerIcon from "@/public/img/icons/hamburger-menu-icon";
import { Button } from "@/components-modern/atoms/button/Button";
import { MarketingSitePaths } from "@hours/constants";
import { useTrackEvent } from "@/services";
import { navbarAnalyticsInfo } from "@/utils/analyticsConstants";
import { useHasCramMode } from "@/hooks/Library/useHasCramMode";
import { useSpecificTheme } from "@/hooks/useSpecificTheme";
import { MobileNavContent } from "@/components/Layouts/NewNavbar/AvatarMenu";
import useProfile from "@/utils/useProfile";
import SaveSubjectButton from "@/components-modern/molecules/save-subject-button/SaveSubjectButton";

const LogoLink = dynamic(() => import("@/components/Layouts/NewNavbar/LogoLink"), { ssr: false });

const ANCHOR_LOCATION = "left";
const MobileNav = ({
  pathname,
  pathParams,
}: {
  pathParams:
    | Record<string, string | string[] | undefined>
    | { [key: string]: string | string[] | undefined };
  pathname: string;
}): JSX.Element => {
  const theme = useSpecificTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { trackEvent } = useTrackEvent();
  const hasCramMode = useHasCramMode();
  const CTALabel = "Unlock Cram Mode";
  const { profile } = useProfile();
  const trackCTAClick = () => {
    trackEvent({
      ...navbarAnalyticsInfo.NAV_ITEM_CLICK,
      label: CTALabel,
    });
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const iconSx = {
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: "transparent",
      color: `${theme.palette.primary.main} !important`,
    },
    stroke: (strokeTheme) => strokeTheme.palette.common.black,
    opacity: 0.8,
  };

  return (
    <div>
      <React.Fragment key={ANCHOR_LOCATION}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Box sx={{ mt: 1, ml: 2 }}>
            <LogoLink />
          </Box>
          <Box>
            {!hasCramMode && (
              <Button onClick={trackCTAClick} variant="secondary" href={MarketingSitePaths.CRAM_MODE}>
                {CTALabel}
              </Button>
            )}
            <SaveSubjectButton pathname={pathname} pathParams={pathParams} />
            <IconButton disableRipple aria-label="menu" sx={iconSx} onClick={toggleDrawer(true)}>
              <HamburgerIcon />
            </IconButton>
          </Box>
        </Box>
        <Drawer anchor={ANCHOR_LOCATION} open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box role="presentation" onKeyDown={toggleDrawer(false)} sx={{ display: "flex", height: "100vh" }}>
            <MobileNavContent user={profile} isMobile />
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default MobileNav;
