import React from "react";
import { IconButton, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpecificTheme } from "@/hooks/useSpecificTheme";

export interface IGenericModalProps {
  id: string;
  open: boolean;
  title?: string;
  children?: React.ReactNode;
  onCancel?: (event?: unknown, reason?: string) => void;
  sx?: SxProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paperStyles?: any;
  dialogContentStyles?: SxProps;
  borderRadius?: string;
  backgroundImgPath?: string;
  showCloseButton?: boolean;
  closeButtonColor?: string;
  keepMounted?: boolean;
}

// if there is an onCancel, it should be automatically called here when user presses the escape key, no need to add a keyPress handler for that, neat!
const GenericModal = ({
  id,
  open,
  title,
  children,
  onCancel,
  sx = {},
  paperStyles = {},
  dialogContentStyles = {},
  borderRadius,
  backgroundImgPath,
  showCloseButton = false,
  keepMounted = false,
}: IGenericModalProps): JSX.Element => {
  const theme = useSpecificTheme();

  return (
    <Dialog
      keepMounted={keepMounted}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      onClose={onCancel}
      open={open}
      sx={{
        ...sx,
        "& .MuiPaper-root": {
          borderRadius,
          maxWidth: "none",
          backgroundImage: `url(${backgroundImgPath})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          ...paperStyles,
        },
      }}>
      <DialogTitle id={`${id}-title`}>
        <Typography component="span" variant="h5" sx={{ fontWeight: "600" }}>
          {title || ""}
        </Typography>
        {showCloseButton && (
          <IconButton
            onClick={onCancel}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: 15,
              right: 15,
              zIndex: 100,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: theme.palette.text.primary,
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={dialogContentStyles}>{children}</DialogContent>
    </Dialog>
  );
};

export default GenericModal;
