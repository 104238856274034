import {
  AutocompleteProps as MuiAutocompleteProps,
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
} from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

// T is set to generic T for the generic type of the options.
// Multiple is set to boolean to indicate whether multiple options can be selected.
// DisableClearable is set to boolean to indicate whether the clearable button should be disabled.
// FreeSolo is set to boolean to indicate whether free solo mode is enabled.
// ChipComponent is set to React.ElementType<any> for the type of the chip component.
export type AutocompleteProps<T> = MuiAutocompleteProps<
  T,
  boolean,
  boolean,
  boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.ElementType<any>
> & {
  [key: `data-${string}`]: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Autocomplete: React.FC<AutocompleteProps<any>> = ({ ...props }) => {
  return (
    <MuiAutocomplete
      renderInput={(params) => (
        <MuiTextField {...params} placeholder={props.placeholder} variant="outlined" size={props.size} />
      )}
      clearIcon={<ClearRoundedIcon color="primary" fontSize="small" />}
      popupIcon={<ArrowDropDownRoundedIcon color="primary" fontSize="small" />}
      {...props}
    />
  );
};
