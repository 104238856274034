export const CLICK_EVENT_BUTTON = {
  category: "events",
  action: "click-event-button",
};

export const REGISTRATION_INITIATED = {
  category: "events",
  action: "registration-initiated",
};

export const CLICK_EVENT_CHIP = {
  category: "events",
  action: "click-event-chip",
};

export const CLICK_EVENT_PAGE_CTA = {
  category: "events",
  action: "click-event-page-cta",
};

export const CLICK_OPEN_SUBJECTS = {
  category: "mystuff",
  action: "click-add-subject",
};

export const CLICK_UPDATE_SUBJECTS = {
  category: "top-nav-mystuff",
  action: "click-update-subjects",
};

export const CLICK_EVENT_MORE_INFO = {
  category: "mystuff",
  action: "click-event-more-info",
};

export const CLICK_EVENT_VIEW_SLIDES = {
  category: "mystuff",
  action: "click-event-view-slides",
};
export const CLICK_EVENT_REPLAY_LINK = {
  category: "mystuff",
  action: "click-event-replay-link",
};

export const CLICK_EVENT_LINK = {
  category: "mystuff",
  action: "click-event-link",
};
