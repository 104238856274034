// TODO: need a way to to this for guides without keytopics
import { Content } from "@/store/api/graphql/generated/types";

interface IgetPathProps {
  resource: Content;
  subSlug: string;
  unitSlug: string;
}

export const getPath = ({ resource, subSlug, unitSlug }: IgetPathProps): string => {
  // get relevant info for constructing the url
  const { slug: contentSlug, type: contentType, id: contentId } = resource || {};

  // format the content type for the url
  const formattedContentType = contentType?.toLowerCase()?.replace("_", "-")?.replace("live review", "watch");

  // assemble the path
  const path = `/${subSlug}/${unitSlug}/${contentSlug}/${formattedContentType}`
    .toLowerCase()
    .replace("?", "");

  return `${path}/${contentId}`;
};

interface IgetPagePathReturn {
  isFirstGuide: boolean;
  isLastGuide: boolean;
  nextUrl: string;
  prevUrl: string;
}

interface IgetPagePathProps {
  sortedContent: IContent[];
  subSlug: string;
  unitSlug: string;
  docId: string;
}

export const getPagePath = ({
  sortedContent,
  subSlug,
  unitSlug,
  docId,
}: IgetPagePathProps): IgetPagePathReturn => {
  if (!sortedContent)
    return {
      isFirstGuide: false,
      isLastGuide: false,
      nextUrl: "/",
      prevUrl: "/",
    };
  // find the index of the current page
  const contentIndex = sortedContent?.findIndex((r) => r.id === docId);

  const isFirstGuide = contentIndex === 0;
  const isLastGuide = contentIndex === sortedContent.length - 1;

  // find the next one after that
  const contentPathInfoNext = sortedContent?.[contentIndex + 1];

  // find the next one after that
  const contentPathInfoPrev = sortedContent?.[contentIndex - 1];

  const nextUrl = getPath({ resource: contentPathInfoNext, subSlug, unitSlug });

  const prevUrl = getPath({ resource: contentPathInfoPrev, subSlug, unitSlug });

  return { isFirstGuide, isLastGuide, nextUrl, prevUrl };
};

interface IgetNextPathForUnitPageProps {
  resources: IContent[];
  subSlug: string;
  unitSlug: string;
}

export const getNextPathForUnitPage = ({
  resources,
  subSlug,
  unitSlug,
}: IgetNextPathForUnitPageProps): string => {
  const resourcesCopy = [...(resources || [])];
  const sorted = resourcesCopy.sort((a, b) => {
    // Check if keyTopics exists and is not empty for both elements
    if (a.keyTopics?.length > 0 && b.keyTopics?.length > 0) {
      return Number(a.keyTopics[0]) - Number(b.keyTopics[0]);
    }
    // If keyTopics is missing or empty for either element, leave the order unchanged
    return 0;
  });

  // overview guide should always be first with a keyTopic of 0, solves for the case where there is no guide with "overview" in the title
  const overviewGuide = sorted[0];
  const formattedType = overviewGuide?.type
    ?.toLowerCase()
    ?.replace("_", "-")
    ?.replace("live review", "watch");

  const nextUrl = `/${subSlug}/${unitSlug}/${overviewGuide?.slug}/${formattedType}/${overviewGuide?.id}`;

  return nextUrl;
};
